import { FC } from 'react';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import {
  TextWidgetData,
  WidgetCommonProps,
} from '../../../../gql/widget/types';

type Props = { data: TextWidgetData } & WidgetCommonProps;

const TextWidget: FC<Props> = ({ data: { name, text, title }, afterTitle }) => {
  return (
    <div>
      <div className="mb-2">
        <span className="u-margin-right-5 font-18 font-w-500 u-vertical-align-middle">
          {title || name}
        </span>
        {afterTitle}
      </div>
      <div className="p-divider__default u-margin-bottom-10" />
      <ReactMarkdown className="unreset" remarkPlugins={[remarkGfm]}>
        {text}
      </ReactMarkdown>
    </div>
  );
};

export default TextWidget;
