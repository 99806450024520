import React, { FC, ReactNode, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import {
  LOGIN_ERROR_PATH,
  PUBLIC_PAGE_PREFIX,
  useIsAuthRoute,
} from '../Routes';
import NavBar, { NavBarData } from './NavBar';
import Sidebar from './Sidebar';
import { Container, Content } from './styles';

const Layout: FC<{ children: ReactNode }> = ({ children }) => {
  const pathname = useLocation().pathname;
  const isLoginError = pathname === LOGIN_ERROR_PATH;
  const isAuthRoute = useIsAuthRoute();
  const hasNavigation = !isAuthRoute && !isLoginError;
  const [pageTitle, setPageTitle] = useState('Polyteia');
  const [pageActions, setPageActions] = useState<React.JSX.Element[] | null>(
    null,
  );
  const isPublicPage = window.location.pathname.includes(
    `/${PUBLIC_PAGE_PREFIX}/`,
  );

  return (
    <Container adjustForTopNav={hasNavigation}>
      <NavBarData.Provider
        value={{ pageTitle, setPageTitle, pageActions, setPageActions }}
      >
        {hasNavigation && (
          <>
            <Sidebar />
            <NavBar />
          </>
        )}
        {isPublicPage && <NavBar />}
        <Content isAuthRoute={isAuthRoute}>{children}</Content>
      </NavBarData.Provider>
    </Container>
  );
};

export default Layout;
