import polylabel from 'polylabel';
import React, {
  FC,
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { scaleLinear } from 'd3-scale';
import { useDebouncedCallback } from 'use-debounce';

import L, {
  divIcon,
  LatLngExpression,
  LatLngTuple,
  Map,
  polygon,
} from 'leaflet';
import {
  MapContainer,
  Marker,
  Polygon,
  Tooltip,
  useMap,
  useMapEvents,
} from 'react-leaflet';
import 'leaflet/dist/leaflet.css';

import MapLegend from './MapLegend';
import ZoomControls from './ZoomControls';
import { LeafletMapWrapper } from './styles';
import {
  useColorsDomainStart,
  useLegendData,
  useNormalizedValues,
} from './utils';
import { SCALE_LOGIC } from './types';
import GrayscaleTileLayer from './GrayscaleTileLayer';
import { Hover, MapChartConfig } from '../../../../gql/widget/types';
import { SETTINGS } from '../../../SimpleFilter/WidgetSettings';
import { getFormattedValue } from '../../../../utils/format';
import { colors, getContrastColor } from '../../../../utils/colors';
import { MAP_BG_OPTION } from '../../../SimpleFilter/WidgetSettings/MapBg';
import {
  ErrorComponents,
  WidgetError,
} from '../../components/WidgetErrorComponents';
import { getIsDA } from '../../../../gql/user/local';
import { useTranslation } from 'react-i18next';

type PrivateLeafletMap = Map & {
  _onResize: () => void;
};

type LeafletMapProps = {
  data: MapChartConfig;
  settingsVal?: Record<SETTINGS, any>;
  disabled?: boolean;
  handleReload?: () => void;
  handleDelete?: () => void;
};

type LeafletZoomControlsProps = {
  defaultMapPosition: LatLngTuple;
  defaultMapZoom: number;
};

const colorScale = (
  color: string,
  domainStart: number,
  neutralColor: string,
) => {
  return scaleLinear<string>()
    .domain([domainStart, 1])
    .range([neutralColor, color]);
};

const LeafletZoomControls: FC<LeafletZoomControlsProps> = ({
  defaultMapPosition,
  defaultMapZoom,
}) => {
  const leafletMap = useMap();

  return (
    <ZoomControls
      onZoomIncrease={() => leafletMap.zoomIn(0.5)}
      onZoomDecrease={() => leafletMap.zoomOut(1.1)}
      onReset={() => leafletMap.setView(defaultMapPosition, defaultMapZoom)}
    />
  );
};

type ExtendedPolygonProps = {
  index: number;
  coordinates: LatLngExpression[][][];
  fillColor: string;
  strokeColor: string;
  showDataHover: boolean;
  hovers: Array<Hover>;
  tooltipText: string;
  visibleValue: string;
  isValueOverlayEnabled: boolean;
};

const ExtendedPolygon: FC<ExtendedPolygonProps> = ({
  index,
  coordinates,
  fillColor,
  strokeColor,
  showDataHover,
  hovers,
  tooltipText,
  visibleValue,
  isValueOverlayEnabled,
}) => {
  const leafletMap = useMap();
  const [mapZoom, setMapZoom] = useState(leafletMap.getZoom());

  useMapEvents({
    zoomend(event: { target: Map }) {
      const updatedZoomLevel = event.target.getZoom();

      setMapZoom(updatedZoomLevel);
    },
  });

  const isStaticTooltipVisible = useMemo(() => {
    if (!isValueOverlayEnabled || !mapZoom) {
      return false;
    }

    const polygonBounds = polygon(coordinates).getBounds();
    const northWestBoundaryInPixels = leafletMap.latLngToContainerPoint(
      polygonBounds.getNorthWest(),
    );
    const southEastBoundaryInPixels = leafletMap.latLngToContainerPoint(
      polygonBounds.getSouthEast(),
    );

    const polygonHorizontalBoundsInPixels = Math.abs(
      northWestBoundaryInPixels.x - southEastBoundaryInPixels.x,
    );

    const polygonVerticalBoundsInPixels = Math.abs(
      northWestBoundaryInPixels.y - southEastBoundaryInPixels.y,
    );

    const symbolWidthInPixels = 10;
    const symbolHeightInPixels = 15;
    const labelLengthInPixels = visibleValue.length * symbolWidthInPixels;

    const doesFitHorizontally =
      polygonHorizontalBoundsInPixels > labelLengthInPixels;
    const doesFitVertically =
      polygonVerticalBoundsInPixels > symbolHeightInPixels;

    return doesFitHorizontally && doesFitVertically;
  }, [visibleValue, leafletMap, mapZoom, coordinates, isValueOverlayEnabled]);

  const shouldShowFloatingTooltips = Boolean(showDataHover || hovers.length);

  return (
    <Polygon
      positions={coordinates}
      pathOptions={{
        fillColor,
        fillOpacity: 1,
        weight: 0.35,
        color: strokeColor,
      }}
      smoothFactor={0}
    >
      {shouldShowFloatingTooltips && (
        <Tooltip sticky direction="top" opacity={1}>
          <Fragment>
            {showDataHover && (
              <p>
                {tooltipText}: {visibleValue}
              </p>
            )}
            {hovers.map(({ values, labels, title, type }, i) => (
              <p key={i}>
                {labels[index] || title}:{' '}
                {getFormattedValue(values[index], type)}
              </p>
            ))}
          </Fragment>
        </Tooltip>
      )}
      {isStaticTooltipVisible && (
        <Marker
          interactive={false}
          position={
            polylabel(coordinates[0] as number[][][]) as unknown as [
              number,
              number,
            ]
          }
          icon={divIcon({
            html: `<span style="color: ${getContrastColor(
              fillColor,
            )};">${visibleValue}</span>`,
          })}
        />
      )}
    </Polygon>
  );
};

const DEFAULT_ZOOM = 90000;
const DEFAULT_X = 13.4;
const DEFAULT_Y = 52.5;

const DragDisabler: FC<{ disabled?: boolean }> = ({ disabled }) => {
  const { dragging } = useMap();

  useEffect(() => {
    dragging?.[disabled ? 'disable' : 'enable']?.();
  }, [disabled, dragging]);

  return null;
};

export const LeafletMap: FC<LeafletMapProps> = ({
  data: mapChartConfig,
  settingsVal,
  disabled,
  handleReload,
  handleDelete,
}) => {
  const isDA = getIsDA();
  const isEditing = disabled;
  const defaultMapPosition: [number, number] = [
    parseFloat(mapChartConfig.center.y) || DEFAULT_Y,
    parseFloat(mapChartConfig.center.x) || DEFAULT_X,
  ];
  const { t } = useTranslation();

  const defaultMapZoom =
    (parseFloat(mapChartConfig.zoom) || DEFAULT_ZOOM) / 9000;

  const valuesData = mapChartConfig?.data[0];

  const originalValues = useMemo(
    () => (valuesData?.values || []).map((el: string) => parseFloat(el)),
    [valuesData?.values],
  );

  const scaleLogic = mapChartConfig?.scaleLogic || SCALE_LOGIC.FROM_ZERO;
  const isFromMin = scaleLogic === SCALE_LOGIC.FROM_MIN;
  const dataType = valuesData?.type;

  const legendData = useLegendData(originalValues);

  const values = useNormalizedValues(
    originalValues,
    legendData.positives,
    legendData.negatives,
  );

  const { positiveColorDomainStart, negativeColorDomainStart, minP, maxN } =
    useColorsDomainStart(values, isFromMin);

  const colorsMap = useMemo(() => {
    const negativeColor =
      mapChartConfig?.negativeColor || colors.secondExtended7;
    const positiveColor = mapChartConfig?.positiveColor || colors.primary0;
    const neutralColor = mapChartConfig?.neutralColor || colors.white;

    const minPositiveColor = colorScale(
      positiveColor,
      positiveColorDomainStart,
      neutralColor,
    )(minP);

    const minNegativeColor = colorScale(
      negativeColor,
      negativeColorDomainStart,
      neutralColor,
    )(-maxN);

    return {
      negativeColor,
      positiveColor,
      neutralColor,
      positiveColorDomainStart,
      negativeColorDomainStart,
      minPositiveColor,
      minNegativeColor,
    };
  }, [
    mapChartConfig?.negativeColor,
    mapChartConfig?.positiveColor,
    mapChartConfig?.neutralColor,
    positiveColorDomainStart,
    minP,
    negativeColorDomainStart,
    maxN,
  ]);

  const getShapeColor = useCallback(
    (index: number) => {
      const {
        negativeColor,
        positiveColor,
        neutralColor,
        positiveColorDomainStart,
        negativeColorDomainStart,
      } = colorsMap;

      return colorScale(
        values[index] >= 0 ? positiveColor : negativeColor,
        values[index] >= 0
          ? positiveColorDomainStart
          : negativeColorDomainStart,
        neutralColor,
      )(values[index] >= 0 ? values[index] : -values[index]);
    },
    [colorsMap, values],
  );

  const isValueOverlayEnabled = settingsVal?.[SETTINGS.SHOW_VALUE] ?? false;

  const hideBg =
    mapChartConfig?.background?.type !== 'openstreetmaps' ||
    settingsVal?.[SETTINGS.MAP_BG] === MAP_BG_OPTION.NONE;

  const vectorLayers = useMemo(() => {
    const stringValues = valuesData?.values || [];
    const dataType = valuesData?.type;
    const hovers = mapChartConfig?.hovers || [];
    const showDataHover = valuesData?.showInHover !== false;

    return valuesData.shapes.map((shapeData, index) => {
      const sourceCoordinates = shapeData.multiPolygon?.geometry
        .coordinates ?? [shapeData.polygon?.geometry.coordinates];

      const coordinates = sourceCoordinates.map((group) =>
        group.map((shape) =>
          shape.map(([lng, lat]) => [lat, lng] as LatLngTuple),
        ),
      );

      const tooltipText = valuesData.labels[index];
      const visibleValue = getFormattedValue(stringValues[index], dataType);

      const fillColor = getShapeColor(index);

      return (
        <ExtendedPolygon
          key={index}
          hovers={hovers}
          fillColor={fillColor}
          index={index}
          strokeColor={colors.monochromatic0}
          showDataHover={showDataHover}
          tooltipText={tooltipText}
          coordinates={coordinates}
          visibleValue={visibleValue}
          isValueOverlayEnabled={isValueOverlayEnabled}
        />
      );
    });
  }, [
    getShapeColor,
    isValueOverlayEnabled,
    mapChartConfig?.hovers,
    valuesData.labels,
    valuesData.shapes,
    valuesData?.showInHover,
    valuesData?.type,
    valuesData?.values,
  ]);

  const leafletMapRef = useRef<PrivateLeafletMap | null>(null);
  const currentContainerWidth =
    leafletMapRef.current?.getContainer()?.clientWidth ?? 0;

  const debouncedResizeMapTrigger = useDebouncedCallback(() => {
    leafletMapRef.current?._onResize();
  }, 300);

  useEffect(() => {
    debouncedResizeMapTrigger();
  }, [currentContainerWidth, debouncedResizeMapTrigger]);

  const canvasRenderer = useMemo(() => L.canvas(), []);

  if (!vectorLayers?.length) {
    return (
      <WidgetError
        testId={'incomplete-data'}
        isDA={isDA}
        alertCase={
          isDA || isEditing
            ? ErrorComponents.ALERT_WITHOUT_EDIT
            : ErrorComponents.ALERT_WITH_RELOAD
        }
        errorTitle={isDA ? t('error.widget.filter.title') : ''}
        handleReload={handleReload ? handleReload : () => {}}
        handleDelete={handleDelete ? handleDelete : () => {}}
        editProps={{ id: '', isPreview: false }}
      />
    );
  }

  return (
    <LeafletMapWrapper hideBg={hideBg}>
      <MapContainer
        style={{ width: '100%', height: '100%' }}
        center={defaultMapPosition}
        zoom={defaultMapZoom}
        zoomControl={false}
        ref={leafletMapRef}
        renderer={canvasRenderer}
      >
        <DragDisabler disabled={disabled} />
        {!hideBg && <GrayscaleTileLayer />}

        {vectorLayers}

        <LeafletZoomControls
          defaultMapPosition={defaultMapPosition}
          defaultMapZoom={defaultMapZoom}
        />

        <MapLegend
          isFromMin={isFromMin}
          originalValues={originalValues}
          {...colorsMap}
          {...legendData}
          dataType={dataType}
        />
      </MapContainer>
    </LeafletMapWrapper>
  );
};
