import { gql } from '@apollo/client';
import { metadata } from '../common/fragments';
import { VERSION_TYPE } from '../dataSource/types';
import { pageFragment } from '../page/fragments';
import { Page } from '../page/types';
import { widgetFragment } from '../widget/fragments';
import { InsightRawTableData, QueryCol, Widget } from '../widget/types';
import { queryFragment } from './fragments';
import { Query, QueryConfigFilterType } from './types';

export type GetQueryVersionData = { queryVersion: Query };

export type GetQueryVersionVars = {
  versionId: string;
};

export const GET_QUERY_VERSION = gql`
  query GetQueryVersion($versionId: String!) {
    queryVersion(versionId: $versionId) {
      ...Query
    }
  }
  ${queryFragment}
`;

export type GetAllQueryVersionsData = {
  allQueryVersions: Array<Omit<Query, 'queryConfig'>>;
};

export type GetAllQueryVersionsVars = {
  id: string;
};

export const GET_ALL_QUERY_VERSIONS = gql`
  query GetAllQueryVersions($id: String!) {
    allQueryVersions(id: $id) {
      ${metadata}
    }
  }
`;

export type RawTableData = {
  header: string[];
  content: string[][];
  total: number;
};

export type GetRawQueryTableVars = {
  structureId: string;
  queryVersionId: string;
};

export type GetRawQueryTableData = {
  getRawQueryTableByVersionId: RawTableData;
};

export const GET_RAW_QUERY_TABLE = gql`
  query GetRawQueryTable($structureId: String!, $queryVersionId: String!) {
    getRawQueryTableByVersionId(
      structureId: $structureId
      queryVersionId: $queryVersionId
    ) {
      header
      content
      total
    }
  }
`;

export type GetRawQueryTableByStringVars = {
  structureId: string;
  query: string;
};

export type GetRawQueryTableByStringData = {
  getRawQueryTableByQueryString: RawTableData;
};

export const GET_RAW_QUERY_TABLE_BY_STRING = gql`
  query GetRawQueryTableByString($structureId: String!, $query: String!) {
    getRawQueryTableByQueryString(structureId: $structureId, query: $query) {
      header
      content
      total
    }
  }
`;

export type GetRawQueryTableByStringFromDataSourceVars = {
  dataSourceId: string;
  query: string;
  organizationId?: string;
  structureId?: string;
  first?: number;
};

export type GetRawQueryTableByStringFromDataSourceData = {
  getRawQueryTableByQueryStringFromDataSource: RawTableData;
};

export const GET_RAW_QUERY_TABLE_BY_STRING_FROM_DATA_SOURCE = gql`
  query GetRawQueryTableByStringFromDataSource(
    $dataSourceId: String!
    $query: String!
    $organizationId: String
    $structureId: String
    $first: Float
  ) {
    getRawQueryTableByQueryStringFromDataSource(
      dataSourceId: $dataSourceId
      query: $query
      organizationId: $organizationId
      structureId: $structureId
      first: $first
    ) {
      header
      content
      total
    }
  }
`;

export type GetCatalogsVars = {
  dataSourceId: string;
  filter?: string;
};

export type GetCatalogsData = {
  getCatalogs: {
    dataSourceId: string;
    catalogs: string[];
  };
};

export const GET_CATALOGS = gql`
  query GetCatalogs($dataSourceId: String!, $filter: String) {
    getCatalogs(dataSourceId: $dataSourceId, filter: $filter) {
      dataSourceId
      catalogs
    }
  }
`;

export type GetSchemasVars = {
  dataSourceId: string;
  catalog: string;
  filter?: string;
};

export type GetSchemasData = {
  getSchemas: {
    dataSourceId: string;
    catalog: string;
    schemas: { name: string; tables: string[] }[];
  };
};

export const GET_SCHEMAS = gql`
  query GetSchemas($dataSourceId: String!, $catalog: String!, $filter: String) {
    getSchemas(
      dataSourceId: $dataSourceId
      catalog: $catalog
      filter: $filter
    ) {
      dataSourceId
      catalog
      schemas {
        name
        tables
      }
    }
  }
`;

export type GetTablesVars = {
  dataSourceId: string;
  catalog: string;
  schema: string;
  filter?: string;
};

export type GetTablesData = {
  getTables: {
    dataSourceId: string;
    catalog: string;
    schema: string;
    tables: string[];
  };
};

export const GET_TABLES = gql`
  query GetTables(
    $dataSourceId: String!
    $catalog: String!
    $schema: String!
    $filter: String
  ) {
    getTables(
      dataSourceId: $dataSourceId
      catalog: $catalog
      schema: $schema
      filter: $filter
    ) {
      dataSourceId
      catalog
      schema
      tables
    }
  }
`;

export type QueryUsagesByPagesData = { allQueryUsagesByPages: Page[] };

export type QueryUsagesVars = {
  queryId: string;
};

export const QUERY_USAGES_BY_PAGES = gql`
  query AllQueryUsagesByPages($queryId: String!) {
    allQueryUsagesByPages(queryId: $queryId) {
      ...Page
    }
  }
  ${pageFragment}
`;

export type QueryUsagesByWidgetsData = {
  allQueryUsagesByWidgets: Widget[];
};

export const QUERY_USAGES_BY_WIDGETS = gql`
  query AllQueryUsagesByWidgets($queryId: String!) {
    allQueryUsagesByWidgets(queryId: $queryId) {
      ...Widget
    }
  }
  ${widgetFragment}
`;

export type SearchQueriesData = { searchQueryByType: Query[] };

export type SearchQueriesVars = {
  text: string;
  versionType: VERSION_TYPE;
  typeFilter?: QueryConfigFilterType;
  first?: number;
};

export const SEARCH_QUERIES = gql`
  query SearchQueryByType(
    $text: String!
    $versionType: String!
    $typeFilter: String
    $first: Float
  ) {
    searchQueryByType(
      text: $text
      versionType: $versionType
      typeFilter: $typeFilter
      first: $first
    ) {
      ...Query
    }
  }
  ${queryFragment}
`;

export type GetAllQueriesData = { allQueriesByType: Query[] };

export type GetAllQueriesVars = {
  versionType: VERSION_TYPE;
};

export const GET_ALL_QUERIES = gql`
  query SearchQueryByType($versionType: String!) {
    allQueriesByType(versionType: $versionType) {
        ${metadata}
        queryConfig {
            name
            title
        }
    }
  }
`;

export type GetAllQueryNonLiveUniqueIdsData = {
  allQueryNonLiveUniqueIDs: string[];
};

export type GetAllQueryNonLiveUniqueIdsVars = null;

export const GET_ALL_QUERY_NON_LIVE_UNIQUE_IDS = gql`
  query GetAllQueryNonLiveUniqueIds {
    allQueryNonLiveUniqueIDs
  }
`;

export type GetQueryPreviewVars = {
  queryId: string;
  dataSourceId?: string;
  organizationId?: string;
  structureId?: string;
  limit?: number;
  isCsv?: boolean;
};

export type GetQueryPreviewData = {
  getInsightDataSetPreview: InsightRawTableData;
};

export const GET_QUERY_PREVIEW = gql`
  query GetInsightDataSetPreview(
    $queryId: String!
    $dataSourceId: String
    $organizationId: String
    $structureId: String
    $limit: Float
  ) {
    getInsightDataSetPreview(
      queryId: $queryId
      dataSourceId: $dataSourceId
      organizationId: $organizationId
      structureId: $structureId
      limit: $limit
    ) {
      header {
        type
        title
        name
        valuesType
      }
      content
      total
    }
  }
`;

export type GetQueryHeadersVars = {
  dataSourceId: string;
  queryId: string;
  organizationId?: string;
  structureId?: string;
};

export type GetQueryHeadersData = {
  getQueryHeaders: QueryCol[];
};

export const GET_QUERY_HEADERS = gql`
  query GetQueryHeaders(
    $dataSourceId: String!
    $queryId: String!
    $organizationId: String
    $structureId: String
  ) {
    getQueryHeaders(
      dataSourceId: $dataSourceId
      queryId: $queryId
      organizationId: $organizationId
      structureId: $structureId
    ) {
      type
      title
      name
      valuesType
    }
  }
`;

export type GetLiveQueryVars = {
  id: string;
};
export type GetLiveQueryData = {
  queryLiveVersion: {
    datasourceId?: string;
    organizationId?: string;
    queryConfig: {
      query: string;
    };
  };
};

export const GET_LIVE_QUERY = gql`
  query GetLiveQuery($id: String!) {
    queryLiveVersion(id: $id) {
      datasourceId
      organizationId
      queryConfig {
        query
      }
    }
  }
`;
