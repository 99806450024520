import { FC, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getIsDA } from '../../../../gql/user/local';
import {
  ChartDomainConfig,
  Highlight,
  SORT_ORDER,
  WidgetCommonProps,
  WidgetStand,
} from '../../../../gql/widget/types';
import { TransformedFilter } from '../../../../utils/filters';
import GraphStand from '../../../GraphStand';
import SimpleFilter, { FilterSelection } from '../../../SimpleFilter';
import { Table } from '../../../Table';
import {
  ErrorComponents,
  WidgetError,
} from '../../components/WidgetErrorComponents';
import { ROW_TYPES } from './@constants';
import { SimpleTableColumn, SimpleTableFooter, SimpleTableRow } from './@types';
import { TableWrapper } from './styles';

const { SUMMARY } = ROW_TYPES;

export interface BaseWidgetData {
  header: string | null;
  filters: TransformedFilter[] | null;
  filtersVal?: FilterSelection[];
  info?: string;
  tiltLabels?: boolean;
  bgHighlights?: Highlight[];
  stand?: WidgetStand;
  maxValue?: number;
  xAxis?: ChartDomainConfig;
  yAxis?: ChartDomainConfig;
}

export interface SimpleTableWidget extends BaseWidgetData {
  sortable?: boolean;
  columns: SimpleTableColumn[];
  rows: SimpleTableRow[];
  footer?: SimpleTableFooter[];
  spanning_header?: {
    colspan: number;
    border_right?: boolean;
    name?: string;
  }[];
}

type Props = {
  widget: SimpleTableWidget;
  dense?: boolean;
} & WidgetCommonProps;

const SimpleTable: FC<Props> = ({
  widget,
  onFilterChange,
  afterTitle,
  dense,
  handleDelete,
  handleReload,
  editProps,
  isEditing,
}) => {
  const sliderContainerRef = useRef<HTMLDivElement | null>(null);

  const {
    sortable,
    header,
    filters,
    columns,
    rows,
    footer,
    spanning_header,
    filtersVal,
    info,
    stand,
  } = widget;
  const isDA = getIsDA();
  const { t } = useTranslation();
  const [sortOpts, setSortOpts] = useState<{
    colInd: number;
    order: SORT_ORDER;
  } | null>(null);

  const totalWidth = columns.reduce((a, b) => a + (b?.weight ?? 0), 0);

  const headerAlign: { [key: string]: string } = {
    right: 'u-justify-flex-end' as string,
    left: '' as string,
    center: 'u-justify-center' as string,
  };

  const sortedRows = useMemo(() => {
    if (!sortOpts) {
      return rows;
    }

    const { colInd, order } = sortOpts;
    const isNumbers = !Number.isNaN(+rows[0].cells[colInd].value);

    const nullRows = rows.filter((row) => row.cells[colInd].value === null);

    const nonNullRows = rows
      .filter((row) => row.cells[colInd].value !== null)
      .sort(
        isNumbers
          ? (a, b) => {
              const aVal = +a.cells[colInd].value.replace(/,/g, '.');
              const bVal = +b.cells[colInd].value.replace(/,/g, '.');
              const diff = aVal - bVal;

              return order === SORT_ORDER.ASC ? diff : -diff;
            }
          : (a, b) => {
              const isDate = (date: string) =>
                new Date(date).toString() !== 'Invalid Date';

              // Handles date sorting
              const formatValue = (value: string) => {
                if (!isDate(value)) {
                  return value.toLowerCase();
                }

                return new Date(value).getTime();
              };

              const valA = formatValue(a.cells[colInd].value);
              const valB = formatValue(b.cells[colInd].value);

              const isASC = order === SORT_ORDER.ASC;

              if (valA < valB) {
                return isASC ? -1 : 1;
              }

              if (valA > valB) {
                return isASC ? 1 : -1;
              }

              return 0;
            },
      );

    return [...nonNullRows, ...nullRows];
  }, [rows, sortOpts]);
  const getAlertCase = () => {
    if (editProps?.id && isDA) {
      return ErrorComponents.ALERT_WITH_EDIT;
    }
    if ((!isDA && isEditing) || (isDA && !editProps?.id)) {
      return ErrorComponents.ALERT_WITHOUT_EDIT;
    }
    return ErrorComponents.ALERT_WITH_RELOAD;
  };

  return (
    <TableWrapper className="widget flex flex-col">
      <SimpleFilter
        header={header}
        filters={filters}
        info={info}
        onFilterSelect={onFilterChange}
        value={filtersVal}
        afterTitle={afterTitle}
        className="u-margin-bottom-40"
        sliderContainerRef={sliderContainerRef}
      />
      {!sortedRows.length ? (
        <WidgetError
          testId={'incomplete-data'}
          isDA={isDA}
          errorTitle={isDA ? t('error.widget.filter.title') : ''}
          alertCase={getAlertCase()}
          handleReload={handleReload ? handleReload : () => {}}
          handleDelete={handleDelete ? handleDelete : () => {}}
          editProps={editProps?.id ? editProps : { id: '', isPreview: false }}
        />
      ) : (
        <Table
          sortable={sortable}
          headers={columns}
          rows={rows}
          footers={footer}
        />
      )}
      <div ref={sliderContainerRef} />
      <div className="d-flex u-justify-flex-end">
        <GraphStand stand={stand} />
      </div>
    </TableWrapper>
  );
};

export default SimpleTable;
