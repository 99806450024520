import styled from 'styled-components';
import { SIDEBAR_WIDTH } from './Sidebar/styles';

export const Container = styled.div<{ adjustForTopNav: boolean }>`
  width: 100vw;
  height: 100vh;
  display: flex;
  overflow-x: hidden;
  ${({ adjustForTopNav }) => adjustForTopNav && `padding-top: 64px;`}
`;

export const Content = styled.div<{ isAuthRoute: boolean }>`
  width: ${({ isAuthRoute }) =>
    isAuthRoute ? '100%' : `calc(100% - ${SIDEBAR_WIDTH}px)`};
  height: 100%;
  margin-left: ${({ isAuthRoute }) => (isAuthRoute ? 0 : `${SIDEBAR_WIDTH}px`)};
`;
