import styled from 'styled-components';
import { Button } from '@blueprintjs/core';

export const RedirectToEditorBtn = styled(Button)`
  width: 30px;
  height: 30px;

  & > svg {
    width: 25px;
    height: 25px;
  }
`;
