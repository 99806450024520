import React, { FC, useCallback, useEffect, useState } from 'react';
import { getLocalUserData } from '../../gql/user/local';
import { USER_ROLES } from '../../gql/user/types';
import { ENTITY_TYPES } from '../../gql/types';
import { useQuery } from '@apollo/client';
import {
  GET_WIDGET_VERSION,
  LIVE_WIDGET_VERSION,
} from '../../gql/widget/queries';
import { GET_PAGE_VERSION, LIVE_PAGE_VERSION } from '../../gql/page/queries';
import { QueryResult } from '@apollo/client/react/types/types';
import { ReactComponent as Edit } from '../../assets/icons/edit.svg';
import { RedirectToEditorBtn } from './styles';
import { VERSION_TYPE } from '../../gql/dataSource/types';
import { useHistory } from 'react-router-dom';
import { PagesRoute, WidgetsRoute } from '../../app/Routes';
import { getIsEditorPage } from '../../containers/Solutions/Structure/editing';
import { useTranslation } from 'react-i18next';

type Props = {
  entityType: EditEntityType;
  id: string;
  isPreview?: boolean;
  asInlineText?: boolean;
  structureId?: string;
};

const ENTITY_PATHS = {
  [ENTITY_TYPES.WIDGET]: WidgetsRoute?.path,
  [ENTITY_TYPES.PAGE]: PagesRoute?.path,
};

const ENTITY_QUERY = {
  [ENTITY_TYPES.WIDGET]: LIVE_WIDGET_VERSION,
  [ENTITY_TYPES.PAGE]: LIVE_PAGE_VERSION,
};

const ENTITY_QUERY_PATH = {
  [ENTITY_TYPES.WIDGET]: 'widgetLiveVersion',
  [ENTITY_TYPES.PAGE]: 'pageLiveVersion',
};

const ENTITY_VERSION_TYPE_QUERY = {
  [ENTITY_TYPES.WIDGET]: GET_WIDGET_VERSION,
  [ENTITY_TYPES.PAGE]: GET_PAGE_VERSION,
};

const ENTITY_VERSION_TYPE_PATH = {
  [ENTITY_TYPES.WIDGET]: 'widgetVersion',
  [ENTITY_TYPES.PAGE]: 'pageVersion',
};

type EditEntityType = ENTITY_TYPES.PAGE | ENTITY_TYPES.WIDGET;

export const useLiveEntity = (
  id: string,
  entityType: EditEntityType,
): QueryResult => {
  return useQuery(ENTITY_QUERY[entityType], { variables: { id }, skip: true });
};

export const useVersionType = (entityType: EditEntityType) => {
  const { refetch } = useQuery(ENTITY_VERSION_TYPE_QUERY[entityType], {
    skip: true,
  });

  return useCallback(
    async (versionId: string) => {
      try {
        const { data } = await refetch({ versionId });

        return data?.[ENTITY_VERSION_TYPE_PATH[entityType]]?.versionType;
      } catch {}
    },
    [entityType, refetch],
  );
};

const PERMITTED_ROLES = [
  USER_ROLES.DATA_ANALYST,
  USER_ROLES.ACCOUNT_MANAGER,
  USER_ROLES.ANALYTICS_ENGINEER,
  USER_ROLES.CUSTOMER,
];

const COLORS_MAP = {
  [VERSION_TYPE.LIVE]: '#282D61',
  [VERSION_TYPE.DRAFT]: '#F26233',
  [VERSION_TYPE.OLD]: 'grey',
};

const EditEntityBtn: FC<Props> = ({
  entityType,
  id,
  isPreview,
  asInlineText,
  structureId,
}) => {
  const history = useHistory();
  const { t } = useTranslation();
  const [versionType, setVersionType] = useState(VERSION_TYPE.LIVE);

  const isPermittedRole = PERMITTED_ROLES.includes(
    getLocalUserData()?.role as USER_ROLES,
  );
  const { refetch } = useLiveEntity(id, entityType);
  const getVersionType = useVersionType(entityType);

  const isHidden = !isPermittedRole || getIsEditorPage(id);
  const inlineButtonStyles =
    'focus-visible:ring-2 rounded-md ring-focusBlue text-blueberry-2 underline hover:text-blueberry-3';
  useEffect(() => {
    if (isPreview && !isHidden) {
      getVersionType(id).then((versionType) => setVersionType(versionType));
    }
  }, [getVersionType, id, isHidden, isPreview]);

  const editEntity = useCallback(async () => {
    const path = ENTITY_QUERY_PATH[entityType];
    let versionId = id;

    try {
      if (!isPreview) {
        const { data } = await refetch({ id });
        versionId = data?.[path]?.versionId;
      }

      const searchParams = new URLSearchParams(history.location.pathname);

      if (!structureId && entityType === ENTITY_TYPES.WIDGET) {
        // eslint-disable-next-line react-hooks/exhaustive-deps
        structureId = history.location.pathname.split('/')[2];
      }

      const historyPushObject = {
        pathname: `${ENTITY_PATHS[entityType]}/${versionId}`,
        state: {
          addTo: {
            path: history.location.pathname,
          },
        },
        search: '',
      };

      if (entityType === ENTITY_TYPES.WIDGET) {
        searchParams.set('structureid', structureId ?? '');
        historyPushObject.search = searchParams.toString();
      }

      history.push(historyPushObject);
    } catch (e) {}
  }, [entityType, history, id, isPreview, refetch, structureId]);

  if (isHidden) {
    return null;
  }

  if (asInlineText) {
    return (
      <button
        aria-label="edit"
        title="Edit"
        onClick={editEntity}
        className={inlineButtonStyles}
      >
        {t('error.widget.custom.edit')}
      </button>
    );
  } else {
    return (
      <RedirectToEditorBtn
        icon={<Edit />}
        className="edit-entity-btn u-padding-0 u-has-transition u-opacity-0 mx-1"
        onClick={editEntity}
        data-html2canvas-ignore
        style={{
          color: COLORS_MAP[versionType],
        }}
      />
    );
  }
};

export default EditEntityBtn;
