import cn from 'classnames';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as SortingCaretsAscendingXSIcon } from '../../assets/icons/ui/xs/sorting-carets-ascending.svg';
import { ReactComponent as SortingCaretsDescendingXSIcon } from '../../assets/icons/ui/xs/sorting-carets-descending.svg';
import { ReactComponent as SortingCaretsDisabledXSIcon } from '../../assets/icons/ui/xs/sorting-carets-disabled.svg';
import { CONFIG_ELEMENT_VALUES_TYPE } from '../../gql/widget/types';
import { getFormattedValue } from '../../utils/format';
import { Badge, Variant } from '../Badge';
import { IconButton } from '../IconButton';
import { InfoIconButton } from '../InfoIconButton';
import { Popover } from '../Popover';
import {
  SimpleTableColumn,
  SimpleTableFooter,
  SimpleTableRow,
} from '../Widget/widgets/simple_table/@types';

type SortState = 'none' | 'ascending' | 'descending';

const getSortIcon = (state: SortState) => {
  switch (state) {
    case 'ascending':
      return SortingCaretsAscendingXSIcon;

    case 'descending':
      return SortingCaretsDescendingXSIcon;

    case 'none':
    default:
      return SortingCaretsDisabledXSIcon;
  }
};

type Props = {
  sortable?: boolean;
  headers?: SimpleTableColumn[];
  rows?: SimpleTableRow[];
  footers?: SimpleTableFooter[];
  onClickColumn?: (column: SimpleTableColumn) => void;
};

const Table = ({ sortable, headers, rows, footers, onClickColumn }: Props) => {
  const { t } = useTranslation();

  const [sortIndex, setSortIndex] = useState(0);

  const [sortState, setSortState] = useState<SortState>('none');

  const sortedIndexes = useMemo(() => {
    if (!rows?.length) {
      return [];
    }

    const indexes = Array.from(rows.keys());

    if (sortState === 'none') {
      return indexes;
    }

    const ascendingIndexes = indexes.sort((a, b) => {
      const cellA = rows[a].cells[sortIndex];
      const cellB = rows[b].cells[sortIndex];
      if (cellA.valueType === cellB.valueType) {
        switch (cellA.valueType) {
          case CONFIG_ELEMENT_VALUES_TYPE.number:
          case CONFIG_ELEMENT_VALUES_TYPE.percent:
          case CONFIG_ELEMENT_VALUES_TYPE.sqkm:
          case CONFIG_ELEMENT_VALUES_TYPE.currencyEUR:
          case CONFIG_ELEMENT_VALUES_TYPE.celsius:
          case CONFIG_ELEMENT_VALUES_TYPE.ugperm3:
          case CONFIG_ELEMENT_VALUES_TYPE.date:
            return Number(cellA.value) - Number(cellB.value);
        }
      }

      return cellA.value.localeCompare(cellB.value, undefined, {
        numeric: true,
      });
    });

    if (sortState === 'ascending') {
      return ascendingIndexes;
    }

    const descendingIndexes = ascendingIndexes.reverse();
    return descendingIndexes;
  }, [rows, sortIndex, sortState]);

  return (
    <div className="relative overflow-x-auto">
      <table className="w-full bg-white text-sm text-concrete-jungle">
        {headers && (
          <thead className="whitespace-nowrap bg-white text-left">
            <tr className="border-y border-concrete-jungle-6">
              {headers.map((header, index) => (
                <th
                  key={index}
                  scope="col"
                  className={cn(
                    'border-concrete-jungle-6 p-6 font-normal text-concrete-jungle-3',
                    {
                      'min-w-[250px] max-w-[500px]': header.type === 'string',
                    },
                    {
                      'border-x': index === 0,
                      'border-r': index > 0,
                    },
                  )}
                >
                  <div className="flex items-center gap-2.5">
                    {(sortable || header.sortable) && (
                      <IconButton
                        ariaLabel={t('common.sort')}
                        size="xs"
                        icon={
                          index === sortIndex
                            ? getSortIcon(sortState)
                            : SortingCaretsDisabledXSIcon
                        }
                        onClick={() =>
                          setSortIndex((prev) => {
                            if (prev === index) {
                              setSortState((prevSortState) => {
                                switch (prevSortState) {
                                  case 'ascending':
                                    return 'descending';

                                  case 'descending':
                                    return 'none';

                                  case 'none':
                                  default:
                                    return 'ascending';
                                }
                              });
                              return prev;
                            } else {
                              setSortState('ascending');
                              return index;
                            }
                          })
                        }
                      />
                    )}
                    {header.name}
                    {(header.info_text || onClickColumn) &&
                      (header.info_text ? (
                        <Popover text={header.info_text}>
                          <InfoIconButton
                            onClick={() => onClickColumn?.(header)}
                          />
                        </Popover>
                      ) : (
                        <InfoIconButton
                          onClick={() => onClickColumn?.(header)}
                        />
                      ))}
                  </div>
                </th>
              ))}
            </tr>
          </thead>
        )}
        {rows && (
          <tbody>
            {sortedIndexes.map((index) => (
              <tr key={index} className="border-b border-concrete-jungle-6">
                {rows[index].cells.map((cell, index) => (
                  <td
                    key={index}
                    className={cn('border-concrete-jungle-6 p-6 font-normal', {
                      'min-w-[250px] max-w-[500px] text-left':
                        headers?.[index].type === 'string',
                      'border-x text-left': index === 0,
                      'border-r': index > 0,
                      'text-right':
                        index > 0 && headers?.[index].type !== 'string',
                    })}
                  >
                    {cell.color ? (
                      <Badge
                        variant={((): Variant => {
                          switch (cell.color) {
                            case 'blue':
                              return 'badge-3';
                            case 'primary':
                              return 'neutral';
                            case 'green':
                              return 'positive';
                            case 'red':
                              return 'negative';
                            case 'grey':
                            default:
                              return 'disabled';
                          }
                        })()}
                        text={getFormattedValue(cell.value, cell.valueType)}
                      />
                    ) : (
                      getFormattedValue(cell.value, cell.valueType)
                    )}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        )}
        {footers && (
          <tfoot className="bg-concrete-jungle-7">
            <tr>
              {footers.map((footer, i) => (
                <td
                  key={i}
                  className={cn(
                    'border-b border-concrete-jungle-6 p-6 font-bold',
                    {
                      'border-x text-left': i === 0,
                      'border-r text-right': i > 0,
                    },
                  )}
                >
                  {`${footer.totalLabel ?? ''}${
                    (footer.totalLabel && footer.totalValue && ': ') ?? ''
                  }${getFormattedValue(
                    String(footer.totalValue ?? ''),
                    CONFIG_ELEMENT_VALUES_TYPE.number,
                  )}`}
                </td>
              ))}
            </tr>
          </tfoot>
        )}
      </table>
    </div>
  );
};

export default Table;
