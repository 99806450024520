import React, {
  FC,
  ReactElement,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { createPortal } from 'react-dom';
import { Button, InputGroup, Position } from '@blueprintjs/core';
import PopoverLegacy from '../Popover/PopoverLegacy';
import { useDebouncedCallback } from 'use-debounce';
import { ReactComponent as InfoPoint } from '../../assets/icons/info-point.svg';
import { ReactComponent as Settings } from '../../assets/icons/settings.svg';
import Tooltip from '../Tooltip';
import {
  SettingsBtn,
  SliderFilterContainer,
  SliderFilterWrapper,
} from './styles';
import SearchableDropdownFilter from './SearchableDropdownFilter';
import WidgetSettings, { Setting, SETTINGS } from './WidgetSettings';
import { colors } from '../../utils/colors';
import { Tooltip2 } from '@blueprintjs/popover2';
import Scenarios from './Scenarios';
import {
  FilterSection,
  FILTER_TYPES,
  isMultipleFilterType,
} from '../../gql/widget/types';
import { TransformedFilter, TOTAL_FILTER_VALUE } from '../../utils/filters';
import Slider from '../Slider';
import {
  TextTitleInput,
  TextTitlePlaceholder,
  TextTitleWrapper,
} from '../../containers/Solutions/Structure/Page/PageHeader/styles';

type Filters = TransformedFilter[] | null;

export interface FilterSelection extends RenderFilterEntry {
  id: string;
  title: string;
  value: any;
  type: FILTER_TYPES;
  'data-track-event'?: string;
}

interface Props {
  header?: string | null;
  info?: string;
  filters?: Filters;
  defaultValue?: FilterSelection[];
  value?: FilterSelection[];
  onFilterSelect?: (simpleFilterSelect: any) => void;
  className?: string;
  resetOnChange?: boolean;
  afterTitle?: ReactElement;
  settings?: Setting[];
  disabledFilters?: string[];
  sliderContainerRef?: React.MutableRefObject<HTMLDivElement | null>;
  setSettingsVal?: React.Dispatch<React.SetStateAction<Record<string, any>>>;
  settingsVal?: Record<string, any>;
  data_series?: any[];
  scenarios?: FilterSection[];
  isSm?: boolean;
  isPage?: boolean;
  isEdit?: boolean;
  onSubtitleChange?: (subtitle: string) => void;
}

type RenderFilterEntry = {
  title: string;
  value: string | number;
  type?: FILTER_TYPES;
  disabled?: boolean;
};

export type UpdateFiltersOptions = {
  noDebounce?: boolean;
  multiple?: boolean;
};

const DEBOUNCE_FILTER_TYPES = [
  FILTER_TYPES.FREETEXT,
  FILTER_TYPES.SLIDER,
  FILTER_TYPES.FREETEXT_VECTOR,
];

const SimpleFilter: FC<Props> = ({
  header,
  info,
  filters,
  defaultValue,
  value,
  onFilterSelect,
  resetOnChange,
  afterTitle,
  className = '',
  disabledFilters,
  sliderContainerRef,
  settings,
  setSettingsVal,
  settingsVal,
  data_series,
  scenarios,
  isSm,
  isPage,
  isEdit,
  onSubtitleChange,
}) => {
  const [showSettings, setShowSettings] = useState<boolean>(
    settingsVal?.isOpen || false,
  );

  const [internalSelectedFilters, setSelectedFilters] = useState<
    FilterSelection[]
  >(value || defaultValue || []);

  const selectedFilters = internalSelectedFilters;

  useEffect(() => {
    if (value) {
      setSelectedFilters(value);
    }
  }, [value]);

  useEffect(() => {
    if (resetOnChange && filters) {
      setSelectedFilters([]);
    }
  }, [filters, resetOnChange]);

  const debouncedUpdate = useDebouncedCallback((newFilters) => {
    onFilterSelect?.(newFilters);
  }, 300);

  const updateSelectedFilters = useCallback(
    (newFilterValue: any, options?: UpdateFiltersOptions) => {
      const newFilters: FilterSelection[] = [...selectedFilters];

      const getNewFilters = (newVal: any) => {
        const existingIndex = selectedFilters.findIndex(
          ({ id: existingId }) => {
            return existingId === newVal.id;
          },
        );

        existingIndex !== -1
          ? newFilters.splice(existingIndex, 1, newVal)
          : newFilters.push(newVal);
      };

      options?.multiple && Array.isArray(newFilterValue)
        ? newFilterValue.forEach((val) => getNewFilters(val))
        : getNewFilters(newFilterValue);

      setSelectedFilters(newFilters);

      !options?.noDebounce &&
      DEBOUNCE_FILTER_TYPES.includes(newFilterValue.type)
        ? debouncedUpdate(newFilters)
        : onFilterSelect?.(newFilters);
    },
    [debouncedUpdate, onFilterSelect, selectedFilters],
  );

  return (
    <div className={`simple_page_filter ${className}`}>
      <div className="u-display-space-between u-align-center-y">
        <div
          className="u-margin-right-30 font-18 font-w-500 align-self-start simple-filter-title"
          data-testid="simple_filter_header"
          style={isPage && isEdit ? { marginBottom: 0 } : {}}
        >
          {isEdit ? (
            <TextTitleWrapper subtitle>
              <TextTitleInput
                id="page-subtitle-visual-input"
                value={header || ''}
                onChange={(e) => {
                  onSubtitleChange?.(e.target.value);
                }}
                onBlur={() => {
                  onSubtitleChange?.((header || '')?.trim());
                }}
              />
              {!header && (
                <TextTitlePlaceholder>
                  Untertitel hinzufügen...
                </TextTitlePlaceholder>
              )}
            </TextTitleWrapper>
          ) : (
            header
          )}
          {info && (
            <Tooltip
              holder={
                <div
                  className="u-vertical-align-middle"
                  style={{
                    marginTop: 1,
                    width: 12,
                    height: 12,
                    marginLeft: 5,
                    color: colors.primary2,
                  }} // Otherwise the SVG doesn't render in the center
                >
                  <InfoPoint style={{ color: '#cacbcb' }} />
                </div>
              }
            >
              <div
                style={{ maxWidth: 350, padding: 5 }}
                className="u-white-space__pre-line font-14"
              >
                {info}
              </div>
            </Tooltip>
          )}
          {!!settings?.length && (
            <SettingsBtn
              icon={<Settings />}
              className="widget-settings-btn u-has-transition mx-1"
              onClick={() => {
                setShowSettings((prev) => {
                  setSettingsVal?.((s) => ({
                    ...s,
                    isOpen: !prev,
                  }));

                  if (!prev) {
                    settings
                      ?.find(({ id }) => id === SETTINGS.SHOW_SETTINGS)
                      ?.onChange?.(true);
                  }

                  return !prev;
                });
              }}
              showSettings={showSettings}
            />
          )}
          {afterTitle}
        </div>
        <div>
          <div
            className="simple-filter-container justify-content-end"
            data-testid={'simple_filter_container'}
          >
            {filters?.map(
              (
                {
                  values,
                  id,
                  title,
                  type,
                  tiltLabels,
                  defaultSelectedFilter,
                  info,
                },
                parentIndex,
              ) => {
                const selectedFilter = selectedFilters.find(
                  ({ id: filterId }) => filterId === id,
                );

                const isDisabled = disabledFilters?.includes(id);

                const firstValue = values[0] || { value: '', title: '' };

                const appliedFilter =
                  selectedFilter || defaultSelectedFilter || firstValue;

                if (
                  type === FILTER_TYPES.SLIDER &&
                  sliderContainerRef?.current
                ) {
                  let valInd = values.findIndex(
                    ({ value }) => value === appliedFilter.value,
                  );

                  if (valInd === -1) {
                    updateSelectedFilters({
                      id,
                      value: firstValue.value,
                      title: firstValue.title,
                      type: FILTER_TYPES.SLIDER,
                    });

                    valInd = 0;
                  }

                  return createPortal(
                    <SliderFilterContainer>
                      <SliderFilterWrapper>
                        <Slider
                          className="widget-filter slider-filter"
                          tiltLabels={tiltLabels}
                          labelRenderer={(ind, opts) => {
                            if (valInd === ind && !opts?.isHandleTooltip) {
                              return '';
                            }

                            const title = values[ind]?.title || '';

                            if (valInd === ind && opts?.isHandleTooltip) {
                              return title;
                            }

                            return (
                              <div>
                                {title.length >= 12
                                  ? `${title.substring(0, 10)}...`
                                  : title}
                              </div>
                            );
                          }}
                          onChange={(ind) => {
                            updateSelectedFilters({
                              id,
                              value: values[ind].value,
                              title: values[ind].title,
                              type: FILTER_TYPES.SLIDER,
                            });
                          }}
                          value={valInd}
                          min={0}
                          max={values.length - 1}
                        />
                      </SliderFilterWrapper>
                    </SliderFilterContainer>,
                    sliderContainerRef.current,
                  );
                }

                let filterComponent: any;

                if (
                  type &&
                  [
                    FILTER_TYPES.FREETEXT,
                    FILTER_TYPES.FREETEXT_VECTOR,
                  ].includes(type)
                ) {
                  const freetextValue = String(selectedFilter?.value || '');

                  filterComponent = (
                    <InputGroup
                      id={id}
                      className={`widget-filter freetext-filter ${
                        freetextValue ? 'has-value' : ''
                      }`}
                      data-track-event={selectedFilter?.['data-track-event']}
                      placeholder={title}
                      disabled={isDisabled}
                      rightElement={
                        freetextValue ? (
                          <Button
                            icon="cross"
                            minimal
                            className="freetext-close-btn"
                            onClick={() =>
                              setSelectedFilters((prevState) => {
                                const newFilters = [...prevState];
                                const selectedFilter = newFilters.find(
                                  ({ id: filterId }) => filterId === id,
                                );

                                if (selectedFilter) {
                                  if (!selectedFilter.value) {
                                    return newFilters;
                                  }

                                  selectedFilter.value = '';
                                  onFilterSelect?.(newFilters);
                                }

                                return newFilters;
                              })
                            }
                          />
                        ) : undefined
                      }
                      onChange={(e) => {
                        const newFilterValue: FilterSelection = {
                          id,
                          value: e.target.value,
                          title: title || '',
                          type,
                        };
                        updateSelectedFilters(newFilterValue);
                      }}
                      value={freetextValue}
                    />
                  );
                } else if (type === FILTER_TYPES.DROPDOWN) {
                  filterComponent = (
                    <PopoverLegacy
                      className={`widget-filter dropdown-filter`}
                      multiline
                      disabled={isDisabled}
                      selections={values}
                      selectedContent={appliedFilter}
                      onClick={({ selected }) => {
                        const initialFilter =
                          defaultSelectedFilter || firstValue;

                        const isActive =
                          selectedFilter?.value === selected.value;

                        updateSelectedFilters({
                          id,
                          value: isActive
                            ? initialFilter.value
                            : selected.value,
                          title: isActive
                            ? initialFilter.title
                            : selected.title,
                          type: FILTER_TYPES.DROPDOWN,
                        });
                      }}
                      position={Position.BOTTOM_RIGHT}
                      data-track-event={selectedFilter?.['data-track-event']}
                    />
                  );
                } else if (
                  [
                    FILTER_TYPES.SEARCHABLE_DROPDOWN,
                    FILTER_TYPES.MULTISELECT_SEARCHABLE,
                    FILTER_TYPES.MULTISELECT_DROPDOWN,
                  ].includes(type as FILTER_TYPES)
                ) {
                  const multiple = isMultipleFilterType(type);

                  const defaultMultipleFilter =
                    selectedFilter ??
                    (defaultSelectedFilter && {
                      value: [defaultSelectedFilter.value],
                    });

                  const defaultFilter = multiple
                    ? defaultMultipleFilter
                    : defaultSelectedFilter;

                  filterComponent = (
                    <SearchableDropdownFilter
                      multiple={!!multiple}
                      values={values}
                      selectedItem={selectedFilter || defaultFilter || null}
                      defaultItem={defaultSelectedFilter || firstValue}
                      resetItem={
                        firstValue.value === TOTAL_FILTER_VALUE
                          ? firstValue
                          : defaultSelectedFilter || firstValue
                      }
                      onSelect={({ value, title }) => {
                        if (
                          multiple &&
                          selectedFilter &&
                          Array.isArray(selectedFilter.value) &&
                          selectedFilter.value.length === value.length &&
                          selectedFilter.value.every(
                            (val, ind) => val === value[ind],
                          )
                        ) {
                          return;
                        }

                        updateSelectedFilters({
                          id,
                          value,
                          title,
                          type,
                        });
                      }}
                      disabled={isDisabled}
                      disableSearch={type === FILTER_TYPES.MULTISELECT_DROPDOWN}
                    />
                  );
                }

                return (
                  <div
                    key={`${parentIndex}-${id}`}
                    data-testid={`simple_filter_${parentIndex}-${id}`}
                    className="filter-wrapper"
                  >
                    {isMultipleFilterType(type) ? (
                      filterComponent
                    ) : (
                      <Tooltip2
                        content={info}
                        placement="top"
                        openOnTargetFocus={false}
                      >
                        {filterComponent}
                      </Tooltip2>
                    )}
                  </div>
                );
              },
            )}
          </div>
        </div>
      </div>
      {!isPage && <div className="p-divider__default" />}
      <WidgetSettings
        show={showSettings}
        settings={
          scenarios?.length! > 1
            ? settings?.filter(({ id }) => id !== SETTINGS.WIDGET_TYPE)
            : settings
        }
        settingsVal={settingsVal}
        setSettingsVal={setSettingsVal}
        data_series={data_series}
      />
      <Scenarios
        scenarios={scenarios}
        selectedFilters={selectedFilters}
        updateSelectedFilters={updateSelectedFilters}
        isSm={isSm}
        isPage={isPage}
      />
    </div>
  );
};

export default SimpleFilter;
