import {
  ChangeEventHandler,
  Dispatch,
  KeyboardEventHandler,
  ReactNode,
  SetStateAction,
  useId,
  useMemo,
  useRef,
  useState,
} from 'react';

import cn from 'classnames';
import { FieldProps } from 'formik';

import { ReactComponent as SearchIcon } from '../../assets/icons/ui/l/search.svg';
import { includesWordStartsWith } from '../../utils/common';
import { useClickOutside } from '../../utils/hooks/useClickOutside';
import { useKeyEvent } from '../../utils/hooks/useKeyEvent';
import { usePositionStyles } from '../../utils/hooks/usePositionStyles';
import { DropdownList, Props as DropdownListProps } from '../DropdownList';
import { BaseLabelProps, InputLabel } from '../inputs/InputLabel';

type Option<V> = DropdownListProps<V>['items'][number];

type Props<V> = FieldProps<V> & {
  id?: string;
  placeholder: string;
  options: Option<V>[];
  disabledKeys?: (string | number)[];
  type?: string;
  disabled?: boolean;
  trailingSlot?: ReactNode;
  filter?: string;
  setFilter?: Dispatch<SetStateAction<string>>;
} & BaseLabelProps;

export default function SearchSelectInput<V>(props: Props<V>) {
  const {
    id,
    options,
    disabledKeys,
    placeholder,
    type = 'text',
    field,
    form,
    disabled,
    trailingSlot,
    filter: filterExternal,
    setFilter: setFilterExternal,
  } = props;

  const generatedId = useId();

  const [isOpen, setIsOpen] = useState(false);

  const [filterInternal, setFilterInternal] = useState('');
  const filter = filterExternal ?? filterInternal;
  const setFilter = setFilterExternal ?? setFilterInternal;

  const [inputFocus, setInputFocus] = useState(false);

  const inputRef = useRef<HTMLInputElement>(null);

  const { elementRef } = useClickOutside<HTMLDivElement>(
    () => {
      setFilter('');
      setIsOpen(false);
    },
    isOpen,
    [],
  );

  useKeyEvent(
    'Escape',
    () => {
      setFilter('');
      setIsOpen(false);
    },
    isOpen,
  );

  useKeyEvent(
    'Enter',
    () => {
      setIsOpen(true);
      setInputFocus(true);
    },
    inputFocus && !isOpen,
  );

  const filteredOptions = options.filter((option) =>
    includesWordStartsWith(option.label, filter),
  );
  useKeyEvent(
    ['ArrowDown', 'ArrowUp'],
    (e) => {
      e.preventDefault();
      if (inputFocus) {
        setInputFocus(false);
      }
    },
    filteredOptions.length > 0,
  );

  const onSelectHandler = (inputValue: Option<V>['value']) => {
    const selected = options.find(
      (option) => option.value === inputValue,
    )?.label;
    if (!selected) {
      return;
    }
    setFilter(selected);
    setIsOpen(false);
    form.setFieldValue(field.name, inputValue);
  };

  const { styles: positionStyles } = usePositionStyles(elementRef, {
    distance: 8,
  });

  const handleInput: ChangeEventHandler<HTMLInputElement> | undefined = (e) => {
    if ((e.target.value === ' ' && !filter.length) || !inputFocus) {
      return;
    }
    setIsOpen(true);
    setInputFocus(true);
    setFilter(e.target.value);
  };

  const handleInputFocus = () => {
    setInputFocus(true);
  };

  const enableInput = () => {
    setIsOpen(true);
    setInputFocus(true);
  };

  const handleKeyDown: KeyboardEventHandler<HTMLInputElement> | undefined = (
    e,
  ) => {
    if (inputRef.current && e.key === 'ArrowUp') {
      e.preventDefault();
      const end = inputRef.current.value.length;
      inputRef.current.selectionStart = end;
      inputRef.current.selectionEnd = end;
    }
    if (e.key === 'Backspace') {
      form.setFieldValue(field.name, undefined);
      enableInput();
    }
  };

  const handleContainerKeyDown: KeyboardEventHandler = (e) => {
    const isKeyboardInput =
      /^[a-zA-Z0-9\t\n ./<>?;:"'`!@#$%^&*()\\[\]{}_+=|\\-]$/i.test(e.key);
    if (isKeyboardInput) {
      enableInput();
      form.setFieldValue(field.name, undefined);
    }
  };

  const caption = useMemo(() => {
    return options.find((option) => option.value === field.value)?.label;
  }, [field.value, options]);

  return (
    <InputLabel {...props} htmlFor={id || generatedId}>
      <div
        className="flex grow items-center gap-2"
        ref={elementRef}
        onKeyDown={handleContainerKeyDown}
      >
        <div
          className={cn(
            [
              'relative',
              'focus:focused-input',
              'flex',
              'h-11',
              'w-full',
              'flex-row',
              'items-center',
              'justify-start',
              'rounded-md',
              'border',
              'border-concrete-jungle-5',
              'px-4',
              'gap-4',
              'text-sm',
              'text-primary',
              'focus:border-primary',
            ],
            {
              'focused-input': isOpen && inputFocus,
              'focus-within:focused-input': !isOpen,
              'cursor-not-allowed bg-concrete-jungle-8': disabled,
              'bg-white hover:border-concrete-jungle-3': !disabled,
            },
          )}
          data-testid="search-input"
          onFocus={enableInput}
          onClick={enableInput}
          onBlur={() => {
            const option = options.find(
              (option) =>
                // Temporary fix
                JSON.stringify(option.value) === JSON.stringify(field.value),
            );
            setFilter(option ? option.label : '');

            setIsOpen(false);
            setInputFocus(false);
            field.onBlur({ target: { value: field.value, name: field.name } });
          }}
        >
          <SearchIcon
            className={cn({
              'text-concrete-jungle-3': !isOpen || !inputFocus,
              'text-blueberry': isOpen && inputFocus,
            })}
          />
          <input
            ref={inputRef}
            data-testid="text-input"
            disabled={disabled}
            className={cn(
              [
                'flex-1',
                'outline-none',
                'overflow-hidden',
                'whitespace-nowrap',
                'text-ellipsis',
                'text-sm',
                'w-full',
                'h-full',
                'text-concrete-jungle',
                'active:text-blueberry',
                'placeholder:italic',
                'placeholder:text-concrete-jungle-3',
              ],
              {
                'caret-transparent': !inputFocus || !isOpen,
                'cursor-not-allowed bg-concrete-jungle-8': disabled,
                'text-concrete-jungle-3': isOpen,
                'text-disabled': disabled,
              },
            )}
            placeholder={placeholder}
            type={type || 'text'}
            value={caption || filter}
            onChange={handleInput}
            onKeyDown={handleKeyDown}
          />
          {isOpen && (
            <div className="absolute inset-x-0 z-10" style={positionStyles}>
              <DropdownList
                items={filteredOptions}
                disabledKeys={disabledKeys}
                selection={field.value}
                onSelect={onSelectHandler}
                showCheckmark={false}
                multiple={false}
                handleInputFocus={inputFocus ? undefined : handleInputFocus}
                allowSelection={!inputFocus}
                showIcon={true}
              />
            </div>
          )}
        </div>
        {trailingSlot}
      </div>
    </InputLabel>
  );
}
