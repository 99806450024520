import { gql } from '@apollo/client';
import { metadata } from '../common/fragments';

export const defaultAppliedFilterFragment = gql`
  fragment DefaultAppliedFilter on DefaultAppliedFilter {
    filterId
    value
    multiValue
    title
    type
  }
`;

export const defaultFilterFragment = gql`
  fragment DefaultFilter on DefaultWidgetFilter {
    widgetId
    filters {
      ...DefaultAppliedFilter
    }
  }
  ${defaultAppliedFilterFragment}
`;

export const filterScenarioFragment = gql`
  fragment FilterScenario on FilterScenario {
    id
    title
    type
    valuesType
    column
    labels
    showTotal
    totalLabel
    defaultSelectedFilter
    defaultValueColumn
    defaultValueQueryId
    injectionColumn
    queryId
  }
`;

export const filterSectionFragment = gql`
  fragment FilterSection on FilterSection {
    title
    keepDefault
    filters {
      ...FilterScenario
    }
    defaultLabel
    filteredLabel
  }
  ${filterScenarioFragment}
`;

export const builtFilterScenarioFragment = gql`
  fragment BuiltFilterScenarioConfig on BuiltFilterScenarioConfig {
    id
    title
    type
    valuesType
    column
    labels
    showTotal
    totalLabel
    defaultSelectedFilter
    defaultValueColumn
    defaultValueQueryId
    values
  }
`;

export const builtFilterSectionFragment = gql`
  fragment BuiltFilterSectionConfig on BuiltFilterSectionConfig {
    title
    keepDefault
    filters {
      ...BuiltFilterScenarioConfig
    }
    defaultLabel
    filteredLabel
  }
  ${builtFilterScenarioFragment}
`;

export const dependantFilterFragment = gql`
  fragment DependantFilter on DependantFilter {
    id
    column
    valuesType
  }
`;

export const highlightFragment = gql`
  fragment BaseHighlight on BaseHighlight {
    color
    start
    end
    type
    legend
    source
    column
    automaticHighlightsMap
    queryId
  }
`;

export const separatorFragment = gql`
  fragment BaseSeparator on BaseSeparator {
    type
    position
    text
    queryId
  }
`;

export const builtDependantFilterFragment = gql`
  fragment BuiltDependantFilter on BuiltDependantFilter {
    id
  }
`;

export const filterDependenciesFragment = gql`
  fragment FilterDependencies on FilterDependencies {
    page {
      ...DependantFilter
    }
    widget {
      ...DependantFilter
    }
  }
  ${dependantFilterFragment}
`;

export const builtFilterDependenciesFragment = gql`
  fragment BuiltFilterDependencies on BuiltFilterDependencies {
    page {
      ...BuiltDependantFilter
    }
    widget {
      ...BuiltDependantFilter
    }
  }
  ${builtDependantFilterFragment}
`;

export const widgetFilterFragment = gql`
  fragment WidgetFilter on BaseWidgetFilter {
    id
    title
    type
    info
    valuesType
    column
    labels
    totalAlgo
    totalLabel
    showTotal
    injectionColumn
    defaultValueQueryId
    defaultValueColumn
    queryId
    defaultSelectedFilter
    tiltLabels
    dependencies {
      ...FilterDependencies
    }
  }
  ${filterDependenciesFragment}
`;

export const buildWidgetFilterFragment = gql`
  fragment BuiltWidgetFiltersConfig on BuiltWidgetFiltersConfig {
    id
    title
    type
    info
    valuesType
    labels
    totalAlgo
    totalLabel
    showTotal
    defaultSelectedFilter
    defaultValueQueryId
    defaultValueColumn
    tiltLabels
    dependencies {
      ...BuiltFilterDependencies
    }
    values
  }
  ${builtFilterDependenciesFragment}
`;

export const widgetPageFilterFragment = gql`
  fragment WidgetPageFilter on BaseWidgetPageFilter {
    id
    column
    type
    valuesType
  }
`;

export const dataFragment = gql`
  fragment Data on BaseData {
    id
    title
    type
    column
    labels
    info
    showInHover
    color
    highlights {
      ...BaseHighlight
    }
  }
  ${highlightFragment}
`;

export const simpleStatDataFragment = gql`
  fragment Data on SimpleStatData {
    id
    title
    numberColumn
    numberType
    diffColumn
    diffColorColumn
    diffIndicatorColumn
    info
  }
`;

export const mapDataFragment = gql`
  fragment MapChartWidgetData on MapChartWidgetData {
    title
    type
    showInHover
    column
    labels
    shapes
  }
`;

export const buildMapDataFragment = gql`
  fragment BuiltMapChartWidgetData on BuiltMapChartWidgetData {
    title
    type
    values
    labels
    showInHover
    shapes {
      polygon {
        type
        geometry {
          coordinates
          type
        }
      }
      multiPolygon {
        type
        geometry {
          coordinates
          type
        }
      }
    }
  }
`;

export const domainFragment = gql`
  fragment Domain on BaseDomain {
    type
    title
    column
    tiltLabels
    showValue
    showAllLabels
    order
    maxValue
    valuesType
    highlights {
      ...BaseHighlight
    }
    separators {
      ...BaseSeparator
    }
  }
  ${separatorFragment}
  ${highlightFragment}
`;

export const chartHoverFragment = gql`
  fragment ChartHover on ChartHover {
    type
    title
    column
    labels
    refersTo
  }
`;

export const widgetStandFragment = gql`
  fragment WidgetStand on WidgetStand {
    queryId
    query
    dateColumn
    source
  }
`;

export const widgetPivotFilterFragment = gql`
  fragment WidgetPivotFilter on WidgetPivotFilter {
    id
    column
    valuesType
    title
    type
    showTotal
    totalLabel
  }
`;

export const widgetPivotFragment = gql`
  fragment WidgetPivot on WidgetPivot {
    pivotColumn
    dataColumn
    type
    pivotFunction
    info
    orderBy
    orderDirection
    aggregationFunction
    aggregationLabel
    colors
    groupBy
    total
    totalLabel
    totalValue
    filters {
      ...WidgetPivotFilter
    }
  }
  ${widgetPivotFilterFragment}
`;

export const buildChartHoverFragment = gql`
  fragment BuiltChartHover on BuiltChartHover {
    type
    title
    values
    labels
    refersTo
  }
`;

export const widgetConfigFragment = gql`
  fragment WidgetConfig on BaseWidgetConfig {
    type
    name
    title
    queryId
    info
    filterSections {
      ...FilterSection
    }
    widgetFilters {
      ...WidgetFilter
    }
    pageFilters {
      ...WidgetPageFilter
    }
    stand {
      ...WidgetStand
    }
    pivot {
      ...WidgetPivot
    }
  }
  ${widgetFilterFragment}
  ${widgetPageFilterFragment}
  ${widgetStandFragment}
  ${widgetPivotFragment}
  ${filterSectionFragment}
`;

export const widgetUserFragment = gql`
  fragment WidgetUser on WidgetUser {
    groupId
    userId
  }
`;

export const widgetFragment = gql`
  fragment Widget on Widget {
    ${metadata}
    widgetConfig {
      ...WidgetConfig
    }
    access {
      public
      users {
        ...WidgetUser
      }
    }
  }
  ${widgetConfigFragment}
  ${widgetUserFragment}
`;

export const chartDomainConfigFragment = gql`
  fragment ChartDomainConfig on ChartDomainConfig {
    type
    title
    values
    tiltLabels
    showValue
    maxValue
    valuesType
    showAllLabels
    order
    highlights {
      ...BaseHighlight
    }
    separators {
      ...BaseSeparator
    }
  }
  ${separatorFragment}
  ${highlightFragment}
`;

export const chartDataConfigFragment = gql`
  fragment ChartDataConfig on ChartDataConfig {
    id
    type
    title
    values
    labels
    info
    showInHover
    color
    highlights {
      ...BaseHighlight
    }
  }
  ${highlightFragment}
`;

export const lineConfigFragment = gql`
  fragment LineChartConfig on LineChartWidgetConfig {
    type
    name
    title
    queryId
    zeroBased
    showDots
    info
    filterSections {
      ...FilterSection
    }
    widgetFilters {
      ...WidgetFilter
    }
    pageFilters {
      ...WidgetPageFilter
    }
    hovers {
      ...ChartHover
    }
    domains {
      ...Domain
    }
    data {
      ...Data
    }
    stand {
      ...WidgetStand
    }
    pivot {
      ...WidgetPivot
    }
  }
  ${widgetFilterFragment}
  ${widgetPageFilterFragment}
  ${domainFragment}
  ${dataFragment}
  ${chartHoverFragment}
  ${widgetStandFragment}
  ${widgetPivotFragment}
  ${filterSectionFragment}
`;

export const buildLineConfigFragment = gql`
  fragment BuiltLineChartWidgetConfig on BuiltLineChartWidgetConfig {
    type
    name
    title
    zeroBased
    showDots
    info
    queryId
    isCreatedByCustomer
    pageFilters {
      ...WidgetPageFilter
    }
    filterSections {
      ...BuiltFilterSectionConfig
    }
    widgetFilters {
      ...BuiltWidgetFiltersConfig
    }
    hovers {
      ...BuiltChartHover
    }
    domains {
      ...ChartDomainConfig
    }
    data {
      ...ChartDataConfig
    }
    stand {
      ...WidgetStand
    }
    pivot {
      ...WidgetPivot
    }
  }
  ${buildWidgetFilterFragment}
  ${chartDomainConfigFragment}
  ${chartDataConfigFragment}
  ${buildChartHoverFragment}
  ${widgetStandFragment}
  ${widgetPivotFragment}
  ${builtFilterSectionFragment}
  ${widgetPageFilterFragment}
`;

export const lineWidgetFragment = gql`
  fragment LineWidget on LineChartWidget {
    ${metadata}
    widgetConfig {
      ...LineChartConfig
    }
    access {
      public
      users {
        ...WidgetUser
      }
    }
  }
  ${lineConfigFragment}
  ${widgetUserFragment}
`;

export const simpleStatConfigFragment = gql`
  fragment SimpleStatConfig on SimpleStatWidgetConfig {
    type
    name
    title
    queryId
    info
    widgetFilters {
      ...WidgetFilter
    }
    pageFilters {
      ...WidgetPageFilter
    }
    data {
      ...Data
    }
    stand {
      ...WidgetStand
    }
  }
  ${widgetFilterFragment}
  ${widgetPageFilterFragment}
  ${simpleStatDataFragment}
  ${widgetStandFragment}
`;

export const simpleStatFragment = gql`
  fragment SimpleStatWidget on SimpleStatWidget {
    ${metadata}
    widgetConfig {
      ...SimpleStatConfig
    }
    access {
      public
      users {
        ...WidgetUser
      }
    }
  }
  ${simpleStatConfigFragment}
  ${widgetUserFragment}
`;

export const builtSimpleStatDataFragment = gql`
  fragment BuiltSimpleStatData on BuiltSimpleStatData {
    id
    title
    number
    numberType
    diff
    diffColor
    diffIndicator
    info
  }
`;

export const buildSimpleStatConfigFragment = gql`
  fragment BuiltSimpleStatWidgetConfig on BuiltSimpleStatWidgetConfig {
    type
    name
    title
    info
    isCreatedByCustomer
    widgetFilters {
      ...BuiltWidgetFiltersConfig
    }
    data {
      ...BuiltSimpleStatData
    }
    queryId
    stand {
      ...WidgetStand
    }
  }
  ${buildWidgetFilterFragment}
  ${builtSimpleStatDataFragment}
  ${widgetStandFragment}
`;

export const simpleStatWidgetFragment = gql`
  fragment SimpleStatWidget on SimpleStatWidget {
    ${metadata}
    widgetConfig {
      ...SimpleStatConfig
    }
    access {
      public
      users {
        ...WidgetUser
      }
    }
  }
  ${simpleStatConfigFragment}
  ${widgetUserFragment}
`;

export const barConfigFragment = gql`
  fragment BarChartConfig on BarChartWidgetConfig {
    type
    name
    title
    queryId
    zeroBased
    info
    widgetFilters {
      ...WidgetFilter
    }
    pageFilters {
      ...WidgetPageFilter
    }
    hovers {
      ...ChartHover
    }
    domains {
      ...Domain
    }
    data {
      ...Data
    }
    stand {
      ...WidgetStand
    }
    pivot {
      ...WidgetPivot
    }
  }
  ${widgetFilterFragment}
  ${widgetPageFilterFragment}
  ${domainFragment}
  ${dataFragment}
  ${chartHoverFragment}
  ${widgetStandFragment}
  ${widgetPivotFragment}
`;

export const buildBarConfigFragment = gql`
  fragment BuiltBarChartWidgetConfig on BuiltBarChartWidgetConfig {
    type
    name
    title
    zeroBased
    info
    isCreatedByCustomer
    widgetFilters {
      ...BuiltWidgetFiltersConfig
    }
    hovers {
      ...BuiltChartHover
    }
    domains {
      ...ChartDomainConfig
    }
    data {
      ...ChartDataConfig
    }
    queryId
    stand {
      ...WidgetStand
    }
    pivot {
      ...WidgetPivot
    }
  }
  ${buildWidgetFilterFragment}
  ${chartDomainConfigFragment}
  ${chartDataConfigFragment}
  ${buildChartHoverFragment}
  ${widgetStandFragment}
  ${widgetPivotFragment}
`;

export const barWidgetFragment = gql`
  fragment BarWidget on BarChartWidget {
    ${metadata}
    widgetConfig {
      ...BarChartConfig
    }
    access {
      public
      users {
        ...WidgetUser
      }
    }
  }
  ${barConfigFragment}
  ${widgetUserFragment}
`;

export const mapChartCoordinates = gql`
  fragment Coordinates on Coordinates {
    x
    y
  }
`;

export const mapChartWidgetBackgroundFragment = gql`
  fragment MapChartWidgetBackground on MapChartWidgetBackground {
    type
  }
`;

export const mapConfigFragment = gql`
  fragment MapChartConfig on MapChartWidgetConfig {
    type
    name
    title
    queryId
    info
    scaleLogic
    negativeColor
    neutralColor
    positiveColor
    widgetFilters {
      ...WidgetFilter
    }
    pageFilters {
      ...WidgetPageFilter
    }
    hovers {
      ...ChartHover
    }
    data {
      ...MapChartWidgetData
    }
    zoom
    center {
      ...Coordinates
    }
    stand {
      ...WidgetStand
    }
    background {
      ...MapChartWidgetBackground
    }
  }
  ${widgetFilterFragment}
  ${widgetPageFilterFragment}
  ${mapDataFragment}
  ${chartHoverFragment}
  ${mapChartCoordinates}
  ${widgetStandFragment}
  ${mapChartWidgetBackgroundFragment}
`;

export const buildMapConfigFragment = gql`
  fragment BuiltMapChartWidgetConfig on BuiltMapChartWidgetConfig {
    type
    name
    title
    info
    scaleLogic
    negativeColor
    neutralColor
    positiveColor
    isCreatedByCustomer
    widgetFilters {
      ...BuiltWidgetFiltersConfig
    }
    hovers {
      ...BuiltChartHover
    }
    data {
      ...BuiltMapChartWidgetData
    }
    zoom
    center {
      ...Coordinates
    }
    queryId
    stand {
      ...WidgetStand
    }
    background {
      ...MapChartWidgetBackground
    }
  }
  ${buildWidgetFilterFragment}
  ${buildMapDataFragment}
  ${mapChartCoordinates}
  ${buildChartHoverFragment}
  ${widgetStandFragment}
  ${mapChartWidgetBackgroundFragment}
`;

export const mapWidgetFragment = gql`
  fragment MapWidget on MapChartWidget {
    ${metadata}
    widgetConfig {
      ...MapChartConfig
    }
    access {
      public
      users {
        ...WidgetUser
      }
    }
  }
  ${mapConfigFragment}
  ${widgetUserFragment}
`;

export const columnConfigFragment = gql`
  fragment ColumnChartConfig on ColumnChartWidgetConfig {
    type
    name
    title
    queryId
    zeroBased
    info
    widgetFilters {
      ...WidgetFilter
    }
    pageFilters {
      ...WidgetPageFilter
    }
    hovers {
      ...ChartHover
    }
    domains {
      ...Domain
    }
    data {
      ...Data
    }
    stand {
      ...WidgetStand
    }
    pivot {
      ...WidgetPivot
    }
  }
  ${widgetFilterFragment}
  ${widgetPageFilterFragment}
  ${domainFragment}
  ${dataFragment}
  ${chartHoverFragment}
  ${widgetStandFragment}
  ${widgetPivotFragment}
`;

export const buildColumnConfigFragment = gql`
  fragment BuiltColumnChartWidgetConfig on BuiltColumnChartWidgetConfig {
    type
    name
    title
    zeroBased
    info
    isCreatedByCustomer
    widgetFilters {
      ...BuiltWidgetFiltersConfig
    }
    hovers {
      ...BuiltChartHover
    }
    domains {
      ...ChartDomainConfig
    }
    data {
      ...ChartDataConfig
    }
    queryId
    stand {
      ...WidgetStand
    }
    pivot {
      ...WidgetPivot
    }
  }
  ${buildWidgetFilterFragment}
  ${chartDomainConfigFragment}
  ${chartDataConfigFragment}
  ${buildChartHoverFragment}
  ${widgetStandFragment}
  ${widgetPivotFragment}
`;

export const columnWidgetFragment = gql`
  fragment ColumnWidget on ColumnChartWidget {
    ${metadata}
    widgetConfig {
      ...ColumnChartConfig
    }
    access {
      public
      users {
        ...WidgetUser
      }
    }
  }
  ${columnConfigFragment}
  ${widgetUserFragment}
`;

export const tableDataFragment = gql`
  fragment TableWidgetData on TableWidgetData {
    id
    type
    column
    weight
    align
    borderRight
    header
    info
    colorColumn
    infoColumn
    additionalTextColumn
    sortable
    total
    totalLabel
  }
`;

export const tableConfigFragment = gql`
  fragment TableWidgetConfig on TableWidgetConfig {
    type
    name
    title
    queryId
    info
    sortable
    widgetFilters {
      ...WidgetFilter
    }
    pageFilters {
      ...WidgetPageFilter
    }
    data {
      ...TableWidgetData
    }
    stand {
      ...WidgetStand
    }
    pivot {
      ...WidgetPivot
    }
  }
  ${widgetFilterFragment}
  ${widgetPageFilterFragment}
  ${widgetPivotFragment}
  ${tableDataFragment}
  ${widgetStandFragment}
`;

export const tableSpanningHeaderFragment = gql`
  fragment TableSpanningHeader on TableSpanningHeader {
    colspan
    borderRight
    title
  }
`;

export const tableHeaderFragment = gql`
  fragment TableHeader on TableHeader {
    id
    align
    weight
    borderRight
    info
    title
    sortable
  }
`;

export const tableBuildDataFragment = gql`
  fragment BuiltTableData on BuiltTableData {
    id
    type
    additionalTextColumn
    color
    info
    additionalText
    value
    total
    totalLabel
    totalValue
  }
`;

export const buildTableConfigFragment = gql`
  fragment BuiltTableWidgetConfig on BuiltTableWidgetConfig {
    type
    name
    title
    info
    sortable
    isCreatedByCustomer
    widgetFilters {
      ...BuiltWidgetFiltersConfig
    }
    pageFilters {
      ...WidgetPageFilter
    }
    spanningHeader {
      ...TableSpanningHeader
    }
    header {
      ...TableHeader
    }
    data {
      ...BuiltTableData
    }
    stand {
      ...WidgetStand
    }
    pivot {
      ...WidgetPivot
    }
    queryId
  }
  ${buildWidgetFilterFragment}
  ${widgetPageFilterFragment}
  ${tableSpanningHeaderFragment}
  ${tableHeaderFragment}
  ${tableBuildDataFragment}
  ${widgetPivotFragment}
  ${widgetStandFragment}
`;

export const tableWidgetFragment = gql`
  fragment TableWidgetType on TableWidgetType {
    ${metadata}
    widgetConfig {
      ...TableWidgetConfig
    }
    access {
      public
      users {
        ...WidgetUser
      }
    }
  }
  ${tableConfigFragment}
  ${widgetUserFragment}
`;

export const textConfigFragment = gql`
  fragment TextWidgetConfig on TextWidgetConfig {
    type
    name
    text
    title
    variables {
      type
      name
      column
      queryId
    }
  }
`;

export const buildTextFragment = gql`
  fragment BuiltTextWidgetConfig on BuiltTextWidgetConfig {
    type
    name
    text
    title
    isCreatedByCustomer
  }
`;

export const textWidgetFragment = gql`
  fragment TextWidget on TextWidget {
    ${metadata}
    widgetConfig {
      ...TextWidgetConfig
    }
    access {
      public
      users {
        ...WidgetUser
      }
    }
  }
  ${textConfigFragment}
  ${widgetUserFragment}
`;

export const pieDataFragment = gql`
  fragment PieChartData on BaseData {
    title
    type
    column
    labels
    showInHover
  }
`;

export const pieConfigFragment = gql`
  fragment PieChartWidgetConfig on PieChartWidgetConfig {
    type
    name
    title
    queryId
    info
    legendPlacement
    widgetFilters {
      ...WidgetFilter
    }
    pageFilters {
      ...WidgetPageFilter
    }
    data {
      ...PieChartData
    }
    hovers {
      ...ChartHover
    }
    stand {
      ...WidgetStand
    }
  }
  ${widgetFilterFragment}
  ${widgetPageFilterFragment}
  ${pieDataFragment}
  ${chartHoverFragment}
  ${widgetStandFragment}
`;

export const pieWidgetFragment = gql`
    fragment PieChartWidget on PieChartWidget {
        ${metadata}
        widgetConfig {
            ...PieChartWidgetConfig
        }
        access {
            public
            users {
                ...WidgetUser
            }
        }
    }
    ${pieConfigFragment}
    ${widgetUserFragment}
`;

export const buildPieConfigFragment = gql`
  fragment BuiltPieChartWidgetConfig on BuiltPieChartWidgetConfig {
    type
    name
    title
    info
    legendPlacement
    isCreatedByCustomer
    widgetFilters {
      ...BuiltWidgetFiltersConfig
    }
    hovers {
      ...BuiltChartHover
    }
    data {
      ...ChartDataConfig
    }
    stand {
      ...WidgetStand
    }
    queryId
  }
  ${buildWidgetFilterFragment}
  ${chartDataConfigFragment}
  ${buildChartHoverFragment}
  ${widgetStandFragment}
`;
