import { useQuery } from '@apollo/client';
import {
  BUILD_BAR_CHART_WIDGET_BY_VERSION_ID,
  BUILD_BAR_CHART_WIDGET,
  BUILD_GROUPED_COLUMN_CHART_WIDGET_BY_VERSION_ID,
  BUILD_GROUPED_COLUMN_CHART_WIDGET,
  BUILD_LINE_CHART_WIDGET_BY_VERSION_ID,
  BUILD_LINE_CHART_WIDGET,
  BUILD_MAP_CHART_WIDGET_BY_VERSION_ID,
  BUILD_MAP_CHART_WIDGET,
  BUILD_PIE_CHART_WIDGET_BY_VERSION_ID,
  BUILD_PIE_CHART_WIDGET,
  BUILD_SIMPLE_STAT_WIDGET_BY_VERSION_ID,
  BUILD_SIMPLE_STAT_WIDGET,
  BUILD_STACKED_COLUMN_CHART_WIDGET_BY_VERSION_ID,
  BUILD_STACKED_COLUMN_CHART_WIDGET,
  BUILD_TABLE_WIDGET_BY_VERSION_ID,
  BUILD_TABLE_WIDGET,
  BUILD_TEXT_WIDGET_BY_VERSION_ID,
  BUILD_TEXT_WIDGET,
  BuildLineChartWidgetByVersionIdData,
  BuildLineChartWidgetData,
  BuildLineChartWidgetByVersionIdVars,
  BuildWidgetVars,
  GET_WIDGET_TYPE,
  GET_WIDGET_VERSION,
  GetWidgetVersionData,
  GetWidgetVersionVars,
  LIVE_WIDGET_VERSION,
  LiveWidgetVersionData,
  LiveWidgetVersionVars,
  WidgetTypeData,
  WidgetTypeVars,
} from '../../gql/widget/queries';
import { WIDGET_TYPE } from '../../gql/widget/types';
import { ENTITY_SEARCH_TYPES } from '../../gql/types';
import { QueryResult } from '@apollo/client/react/types/types';
import { getIsLocalWidget } from './index';
import { useEditingContext } from '../../containers/Solutions/Structure/editing';
import { useEffect } from 'react';

const WIDGET_QUERY_MAP = {
  [WIDGET_TYPE.LINE_CHART]: BUILD_LINE_CHART_WIDGET,
  [WIDGET_TYPE.PIE_CHART]: BUILD_PIE_CHART_WIDGET,
  [WIDGET_TYPE.TABLE]: BUILD_TABLE_WIDGET,
  [WIDGET_TYPE.STACKED_COLUMN_CHART]: BUILD_STACKED_COLUMN_CHART_WIDGET,
  [WIDGET_TYPE.GROUPED_COLUMN_CHART]: BUILD_GROUPED_COLUMN_CHART_WIDGET,
  [WIDGET_TYPE.BAR_CHART]: BUILD_BAR_CHART_WIDGET,
  [WIDGET_TYPE.CHOROPLETH_MAP]: BUILD_MAP_CHART_WIDGET,
  [WIDGET_TYPE.SIMPLE_STAT]: BUILD_SIMPLE_STAT_WIDGET,
  [WIDGET_TYPE.TEXT]: BUILD_TEXT_WIDGET,
};

const PREVIEW_WIDGET_QUERY_MAP = {
  [WIDGET_TYPE.LINE_CHART]: BUILD_LINE_CHART_WIDGET_BY_VERSION_ID,
  [WIDGET_TYPE.PIE_CHART]: BUILD_PIE_CHART_WIDGET_BY_VERSION_ID,
  [WIDGET_TYPE.TABLE]: BUILD_TABLE_WIDGET_BY_VERSION_ID,
  [WIDGET_TYPE.STACKED_COLUMN_CHART]:
    BUILD_STACKED_COLUMN_CHART_WIDGET_BY_VERSION_ID,
  [WIDGET_TYPE.GROUPED_COLUMN_CHART]:
    BUILD_GROUPED_COLUMN_CHART_WIDGET_BY_VERSION_ID,
  [WIDGET_TYPE.BAR_CHART]: BUILD_BAR_CHART_WIDGET_BY_VERSION_ID,
  [WIDGET_TYPE.CHOROPLETH_MAP]: BUILD_MAP_CHART_WIDGET_BY_VERSION_ID,
  [WIDGET_TYPE.SIMPLE_STAT]: BUILD_SIMPLE_STAT_WIDGET_BY_VERSION_ID,
  [WIDGET_TYPE.TEXT]: BUILD_TEXT_WIDGET_BY_VERSION_ID,
};

export const DATA_PATH_MAP = {
  [WIDGET_TYPE.LINE_CHART]: 'buildLineChartWidget',
  [WIDGET_TYPE.PIE_CHART]: 'buildPieChartWidget',
  [WIDGET_TYPE.TABLE]: 'buildTableWidget',
  [WIDGET_TYPE.STACKED_COLUMN_CHART]: 'buildStackedColumnChartWidget',
  [WIDGET_TYPE.GROUPED_COLUMN_CHART]: 'buildGroupedColumnChartWidget',
  [WIDGET_TYPE.BAR_CHART]: 'buildBarChartWidget',
  [WIDGET_TYPE.CHOROPLETH_MAP]: 'buildMapChartWidget',
  [WIDGET_TYPE.SIMPLE_STAT]: 'buildSimpleStatWidget',
  [WIDGET_TYPE.TEXT]: 'buildTextWidget',
};

export const PREVIEW_DATA_PATH_MAP = {
  [WIDGET_TYPE.LINE_CHART]: 'buildLineChartWidgetByVersionId',
  [WIDGET_TYPE.PIE_CHART]: 'buildPieChartWidgetByVersionId',
  [WIDGET_TYPE.TABLE]: 'buildTableWidgetByVersionId',
  [WIDGET_TYPE.STACKED_COLUMN_CHART]:
    'buildStackedColumnChartWidgetByVersionId',
  [WIDGET_TYPE.GROUPED_COLUMN_CHART]:
    'buildGroupedColumnChartWidgetByVersionId',
  [WIDGET_TYPE.BAR_CHART]: 'buildBarChartWidgetByVersionId',
  [WIDGET_TYPE.CHOROPLETH_MAP]: 'buildMapChartWidgetByVersionId',
  [WIDGET_TYPE.SIMPLE_STAT]: 'buildSimpleStatWidgetByVersionId',
  [WIDGET_TYPE.TEXT]: 'buildTextWidgetByVersionId',
};

export const useWidget = (
  options: any,
  widgetType: WIDGET_TYPE | undefined,
  isPreview: boolean,
  isStructurePreview: boolean,
  inView: boolean,
  skip?: boolean,
): {
  data?: any;
  refetch?: any;
} & QueryResult => {
  const { isCustomerEditorModeEnabled, loadedWidgets, setLoadedWidget } =
    useEditingContext();

  const isLocalWidget = getIsLocalWidget(options?.variables?.source?.widgetId);

  const isNotLive = (isPreview || isStructurePreview) && !isLocalWidget;

  const {
    data: liveData,
    refetch,
    previousData: livePreviousData,
    loading: liveLoading,
    ...liveRest
  } = useQuery<BuildLineChartWidgetData, BuildWidgetVars>(
    WIDGET_QUERY_MAP[widgetType as WIDGET_TYPE] || BUILD_LINE_CHART_WIDGET,
    {
      ...options,
      variables: {
        source: {
          ...options?.variables?.source,
          appliedFilters: isLocalWidget
            ? []
            : options?.variables?.source?.appliedFilters,
          appliedPageFilters: isLocalWidget
            ? []
            : options?.variables?.source?.appliedPageFilters,
        },
      },
      skip: !widgetType || isNotLive || !inView || skip,
      fetchPolicy: isLocalWidget ? 'cache-only' : 'cache-first',
      notifyOnNetworkStatusChange: true,
    },
  );

  const {
    data: previewData,
    refetch: previewRefetch,
    previousData: previewPreviousData,
    loading: previewLoading,
    ...previewRest
  } = useQuery<
    BuildLineChartWidgetByVersionIdData,
    BuildLineChartWidgetByVersionIdVars
  >(
    PREVIEW_WIDGET_QUERY_MAP[widgetType as WIDGET_TYPE] ||
      BUILD_LINE_CHART_WIDGET_BY_VERSION_ID,
    {
      variables: {
        ...options.variables,
        structureSearchType: isStructurePreview
          ? ENTITY_SEARCH_TYPES.VERSION_ID
          : ENTITY_SEARCH_TYPES.ID,
        widgetSearchType: isCustomerEditorModeEnabled
          ? ENTITY_SEARCH_TYPES.ID
          : ENTITY_SEARCH_TYPES.VERSION_ID,
      },
      skip: !widgetType || !isNotLive || !inView || skip,
      fetchPolicy: 'cache-and-network',
      notifyOnNetworkStatusChange: true,
    },
  );

  const dataPathMap = isNotLive ? PREVIEW_DATA_PATH_MAP : DATA_PATH_MAP;

  const data = isNotLive ? previewData : liveData;
  const previousData = isNotLive ? previewPreviousData : livePreviousData;

  const dataPath = dataPathMap[widgetType as WIDGET_TYPE];

  const rest = isNotLive ? previewRest : liveRest;

  const result = ((data || previousData) as any)?.[dataPath];

  useEffect(() => {
    const widgetId = options?.variables?.source?.widgetId;

    if (widgetId && loadedWidgets && !loadedWidgets[widgetId]) {
      previewRefetch();
      setLoadedWidget(widgetId);
    }
  }, [
    loadedWidgets,
    options?.variables?.source?.widgetId,
    previewRefetch,
    setLoadedWidget,
  ]);

  const loading = liveLoading || previewLoading;

  return {
    ...rest,
    loading,
    data: isNotLive
      ? {
          widgetConfig: result,
          isCreatedByCustomer: result?.isCreatedByCustomer,
        }
      : result,
    refetch: isNotLive ? previewRefetch : refetch,
  };
};

export const useWidgetType = (
  id: string,
  isPreview: boolean,
  inView: boolean,
  isLocal?: boolean,
) => {
  const {
    data: widgetTypeData,
    loading: widgetTypeLoading,
    refetch: refetchWidgetType,
    error: widgetTypeError,
  } = useQuery<WidgetTypeData, WidgetTypeVars>(GET_WIDGET_TYPE, {
    variables: { id },
    skip: isPreview || !inView || isLocal,
  });

  const {
    data: widgetVersionData,
    loading: widgetVersionLoading,
    refetch: refetchWidgetVersion,
    error: widgetVersionError,
  } = useQuery<GetWidgetVersionData, GetWidgetVersionVars>(GET_WIDGET_VERSION, {
    variables: { versionId: id },
    skip: !isPreview || !inView || isLocal,
  });

  const data = isPreview
    ? widgetVersionData?.widgetVersion.widgetConfig.type
    : widgetTypeData?.widgetLiveTypeById;

  const loading = widgetTypeLoading || widgetVersionLoading;

  const error = widgetTypeError || widgetVersionError;

  return {
    widgetType: isLocal ? WIDGET_TYPE.TEXT : data,
    loading,
    refetch: isPreview ? refetchWidgetVersion : refetchWidgetType,
    error,
  };
};

export const useLiveWidgetVersionId = (id: string, skip?: boolean) => {
  const { data: widgetTypeData } = useQuery<
    LiveWidgetVersionData,
    LiveWidgetVersionVars
  >(LIVE_WIDGET_VERSION, { variables: { id }, skip });

  return widgetTypeData?.widgetLiveVersion.versionId;
};
