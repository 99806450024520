import { FC, useMemo } from 'react';
import { TableConfig, WidgetCommonProps } from '../../../../gql/widget/types';
import { useFilters } from '../../../../utils/filters';
import SimpleTable from '../simple_table';
import { CELL_TYPES } from '../simple_table/@constants';
import { SimpleTableFooter, SimpleTableRow } from '../simple_table/@types';

type Props = {
  data: TableConfig;
  dense?: boolean;
} & WidgetCommonProps;

const Table: FC<Props> = ({ data, onFilterChange, filtersVal, ...rest }) => {
  const filters = useFilters(data.widgetFilters as any);

  const columns = useMemo(
    () =>
      data.header.map(
        ({ borderRight, info, title, weight, align, sortable }) => ({
          align,
          name: title,
          type: CELL_TYPES.TEXT,
          border_right: borderRight,
          weight,
          info_text: info,
          sortable,
        }),
      ),
    [data.header],
  );
  const footer: SimpleTableFooter[] | undefined = useMemo(() => {
    const row = data.data[0];
    if (row[0].totalLabel || row[1].totalValue) {
      return row.map(({ totalLabel, totalValue }) => ({
        totalLabel,
        totalValue,
      }));
    }
    return undefined;
  }, [data.data]);

  const rows: SimpleTableRow[] = useMemo(
    () =>
      data.data.map((row) => {
        return {
          cells: row.map(({ value, info, type, color, additionalText }) => ({
            value,
            color,
            additional_text: additionalText,
            info_text: info,
            valueType: type,
          })),
        };
      }),
    [data.data],
  );

  return (
    <SimpleTable
      widget={{
        sortable: data.sortable,
        columns,
        rows,
        footer,
        header: data.title,
        filters,
        filtersVal,
        info: data.info,
        stand: data.stand,
      }}
      onFilterChange={onFilterChange}
      {...rest}
    />
  );
};

export default Table;
