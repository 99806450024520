import { useQuery } from '@apollo/client/react/hooks/useQuery';
import { useEffect } from 'react';

import {
  GET_TAGS_BY_ENTITY_ID,
  GetTagsByEntityIdData,
  GetTagsByEntityIdVars,
} from '../gql/structure/queries';
import { CustomerRole } from '../gql/types';
import { NEW_USER, getLocalUserData } from '../gql/user/local';
import { USER_ROLES } from '../gql/user/types';
import { ENV_VAR, getEnvVar } from './common';

declare global {
  interface Window {
    usetifulTags: Record<string, string>;
  }
}

export const USER_ROLE_TEXT = {
  [USER_ROLES.DATA_ANALYST]: 'Data Analyst',
  [USER_ROLES.ACCOUNT_MANAGER]: 'Account Manager',
  [USER_ROLES.PREVIEWER]: 'Previewer',
  [USER_ROLES.CUSTOMER]: 'Customer',
  [USER_ROLES.ANALYTICS_ENGINEER]: 'Analytics Engineer',
};

export const addUsetiful = () => {
  const disableUsetiful = localStorage.getItem('DISABLE_USETIFUL') === 'true';

  if (disableUsetiful) {
    return;
  }

  const addedScript = document.querySelector('#usetifulScript');

  if (addedScript) {
    addedScript.remove();
  }

  const user = getLocalUserData();

  window.usetifulTags = user
    ? {
        role: USER_ROLE_TEXT[user.role],
      }
    : {};

  const head = document.getElementsByTagName('head')[0];
  const script = document.createElement('script');
  script.async = true;
  script.src = 'https://www.usetiful.com/dist/usetiful.js';
  script.setAttribute('id', 'usetifulScript');
  script.dataset.token = getEnvVar(ENV_VAR.USETIFUL_TOKEN);
  head.appendChild(script);
};

const removeNewUser = () => localStorage.removeItem(NEW_USER);

export const useUsetiful = (
  structureAccess?: boolean,
  formAccess?: boolean,
  structureId?: string,
) => {
  const user = getLocalUserData();
  const isNewUser = localStorage.getItem(NEW_USER) === 'true';
  const disableUsetiful = localStorage.getItem('DISABLE_USETIFUL') === 'true';

  const { data: tagsData } = useQuery<
    GetTagsByEntityIdData,
    GetTagsByEntityIdVars
  >(GET_TAGS_BY_ENTITY_ID, {
    variables: { entityId: structureId as string },
    skip: !structureId,
  });

  const tags = tagsData?.getTagsByEntityId;

  useEffect(() => {
    if (disableUsetiful) {
      return;
    }

    if (user) {
      const groups = user.groups.reduce(
        (acc, g) => ({ ...acc, [g?._id]: 'true' }),
        {},
      );

      const isGroupAdmin = user.groups.some((g) => {
        return g?.roles?.includes(CustomerRole.admin);
      });

      const isGroupEditor = user.groups.some((g) => {
        return g?.roles?.includes(CustomerRole.editor);
      });

      const reducedTags = tags?.reduce(
        (acc, { title }) => ({ ...acc, [title]: 'true' }),
        {},
      );

      window.usetifulTags = {
        userID: user.sub,
        role: USER_ROLE_TEXT[user.role],
        ...(structureAccess ? { 'data solution': 'true' } : {}),
        ...(formAccess ? { forms: 'true' } : {}),
        ...groups,
        ...reducedTags,
        ...(user.role === USER_ROLES.CUSTOMER
          ? {
              admin: isGroupAdmin ? 'true' : 'false',
              editor: isGroupEditor ? 'true' : 'false',
            }
          : {}),
        'new user': isNewUser ? 'true' : 'false',
      };

      removeNewUser();
    }

    const head = document.getElementsByTagName('head')[0];
    const script = document.createElement('script');
    script.async = true;
    script.src = 'https://www.usetiful.com/dist/usetiful.js';
    script.setAttribute('id', 'usetifulScript');
    script.dataset.token = getEnvVar(ENV_VAR.USETIFUL_TOKEN);
    head.appendChild(script);

    return () => {
      script.remove();
    };
  }, [disableUsetiful, formAccess, isNewUser, structureAccess, tags, user]);
};
