import { useQuery } from '@apollo/client';
import { Position } from '@blueprintjs/core';
import { ResizeSensor2 } from '@blueprintjs/popover2';
import React, {
  CSSProperties,
  Dispatch,
  FC,
  SetStateAction,
  useCallback,
  useMemo,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import InfiniteScroll from 'react-infinite-scroll-component';
import { FolderTree } from '../../components/FolderTree/FolderTree/FolderTree';
import SkeletonLoader from '../../components/SkeletonLoader';
import { EDIT_PATH } from '../../constants/entities';
import { VERSION_TYPE } from '../../gql/dataSource/types';
import {
  GET_HIERARCHY_ENTITIES,
  GET_HIERARCHY_PAGE_VERSIONS,
  GetHierarchyEntitiesData,
  GetHierarchyEntitiesVars,
  GetHierarchyPageVersionsData,
  GetHierarchyPageVersionsVars,
} from '../../gql/hierarchy/queries';
import { PageTree, StructureTree } from '../../gql/hierarchy/types';
import {
  getIsAnalyticsEngineer,
  getIsCustomer,
  getIsDA,
} from '../../gql/user/local';
import { StructureWrapper } from './styles';

export const TooltipPosition = React.createContext('auto');

export const treeNodeColorMap = {
  [VERSION_TYPE.DRAFT]: 'blue',
  [VERSION_TYPE.LIVE]: 'green',
  [VERSION_TYPE.OLD]: 'grey',
};

const nameless = '<NAMELESS>';

export const CREATE_PAGE_ID = 'CREATE_PAGE_ID';

type Props = {
  hierarchiesData: StructureTree[];
  isLoading: boolean;
  isVersions?: boolean;
  noOldVersions?: boolean;
  openOnNameClick?: boolean;
  rows: number;
  showNested?: boolean;
  styles?: CSSProperties;
  type: 'widgets' | 'page_versions';

  additionalItems?: PageTree[];
  goToVersion: (id?: string, pathname?: string, hierarchyPath?: string) => void;
  onNodeClick?: (event: any, tree: StructureTree) => void;
  scrollNext: () => void;
  setStructureTrees: Dispatch<SetStateAction<StructureTree[]>>;
  hasTooltip?: boolean;
  isAddingFromInsights?: boolean;
  showHierarchyControls?: boolean;
  showVersionAsText?: boolean;
};

const Hierarchy: FC<Props> = ({
  isLoading,
  hierarchiesData,
  setStructureTrees,
  scrollNext,
  rows,
  onNodeClick,
  openOnNameClick = false,
  type = 'widgets',
  goToVersion,
  showNested = false,
  styles: propStyles,
  isVersions,
  noOldVersions,
  additionalItems,
  hasTooltip,
  isAddingFromInsights,
  showHierarchyControls = true,
  showVersionAsText,
}) => {
  const { t } = useTranslation();
  const [height, setHeight] = useState(0);

  const isDA = getIsDA();
  const isCustomer = getIsCustomer();
  const isAE = getIsAnalyticsEngineer();

  const { refetch: getHierarchyEntities } = useQuery<
    GetHierarchyEntitiesData,
    GetHierarchyEntitiesVars
  >(GET_HIERARCHY_ENTITIES, {
    skip: true,
  });

  const { refetch: getAllPageVersions } = useQuery<
    GetHierarchyPageVersionsData,
    GetHierarchyPageVersionsVars
  >(GET_HIERARCHY_PAGE_VERSIONS, {
    skip: true,
  });

  const hierarchy = useMemo(() => {
    return hierarchiesData?.map((structureTree) => {
      const title =
        structureTree?.structure?.structureConfig?.[isDA ? 'name' : 'title'];

      const structureTreeRes = {
        structure: structureTree?.structure,
        name: title || structureTree?.structure?.structureConfig.title,
        color:
          structureTree.pageQuantity || !isDA
            ? treeNodeColorMap[structureTree?.structure?.versionType]
            : 'orange',
        children:
          isDA || showNested
            ? new Array(structureTree.pageQuantity || 0)
                .fill(null)
                .map((_) => ({
                  fakePage: null,
                }))
            : undefined,
        openOnNameClick,
      };

      if (structureTree?.pageTrees?.length) {
        Object.assign(structureTreeRes, {
          children: structureTree?.pageTrees?.map((pageTree) => {
            const pageTreeRes = {
              page: pageTree?.page,
              name: !isDA
                ? pageTree?.page?.pageConfig?.title
                : pageTree?.widgets?.length
                ? pageTree?.page?.pageConfig?.name || nameless
                : pageTree?.page?.pageConfig?.name ||
                  pageTree?.page?.pageConfig?.title,
              color:
                pageTree?.page?.pageConfig?.name != null
                  ? treeNodeColorMap[pageTree?.page?.versionType]
                  : 'orange',
              openOnNameClick,
            };

            if (pageTree?.widgets?.length && isDA) {
              Object.assign(pageTreeRes, {
                children: pageTree?.widgets?.map((widget) => ({
                  name: widget?.widgetConfig?.name || widget?.id,
                  color:
                    widget?.widgetConfig?.name != null
                      ? treeNodeColorMap[widget?.versionType]
                      : 'orange',
                  ...widget,
                })),
              });
            }
            Object.assign(pageTreeRes, { ...pageTree.page });

            return pageTreeRes;
          }),
        });
      }
      Object.assign(structureTreeRes, { ...structureTree.structure });

      return structureTreeRes;
    });
  }, [hierarchiesData, isDA, openOnNameClick, showNested]);

  const onExpandWidgets = useCallback(
    async (versionId: string) => {
      const { data } = await getHierarchyEntities({
        versionId,
      });

      const pageTrees = data.getHierarchyEntitiesByStructure;

      setStructureTrees((prev) => {
        return prev.map((obj) => {
          if (obj.structure.versionId === versionId) {
            if (!pageTrees.length) {
              return { ...obj, pageQuantity: 0 };
            }

            return {
              ...obj,
              pageTrees: [...(additionalItems || []), ...pageTrees],
            };
          }

          return obj;
        });
      });
    },
    [additionalItems, getHierarchyEntities, setStructureTrees],
  );

  const onExpandPageVersions = useCallback(
    async (versionId: string) => {
      const { data } = await getAllPageVersions({
        versionId,
      });

      const pagesAndVersions = data.getHierarchyPageVersionsByStructure;

      setStructureTrees((prev) => {
        return prev.map((obj, index) => {
          if (obj.structure.versionId === versionId) {
            if (!pagesAndVersions.length) {
              return { ...obj, pageQuantity: 0 };
            }

            const pageTrees: PageTree[] = pagesAndVersions.map(
              ({ page, versions }) => {
                let pageVersions = noOldVersions
                  ? versions.filter(
                      ({ versionType }) => versionType !== VERSION_TYPE.OLD,
                    )
                  : versions;

                if (isAE || isCustomer) {
                  // Filters drafts and olds if is AE
                  pageVersions = pageVersions.filter(
                    ({ versionType }) => versionType === VERSION_TYPE.LIVE,
                  );
                }

                return {
                  page: page || pageVersions[0],
                  widgets: pageVersions.map((v) => ({
                    ...v,
                    hasTooltip: true,
                    tooltipNote: (
                      <div>
                        <div>Name: {v.pageConfig.name}</div>
                        <div>Title: {v.pageConfig.title}</div>
                      </div>
                    ),
                  })) as any,
                } as PageTree;
              },
            );

            return {
              ...obj,
              pageTrees: [...(additionalItems ?? []), ...pageTrees],
            };
          }

          return obj;
        });
      });
    },
    [
      getAllPageVersions,
      setStructureTrees,
      noOldVersions,
      isAE,
      isCustomer,
      additionalItems,
    ],
  );

  return (
    <ResizeSensor2
      onResize={(entries) => setHeight(entries[0].contentRect.height)}
      observeParents
    >
      <div className="h-full" style={propStyles}>
        <InfiniteScroll
          dataLength={hierarchiesData?.length || 0}
          height={height - 10}
          next={scrollNext}
          hasMore
          loader={null}
        >
          {isLoading ? (
            <SkeletonLoader rows={rows} />
          ) : (
            <div>
              {hierarchy?.length ? (
                <div style={{ display: 'block', padding: '8px 0' }}>
                  <TooltipPosition.Provider value={Position.RIGHT}>
                    {hierarchy?.map((structureTree, index) => (
                      <StructureWrapper
                        key={structureTree?.structure?.id || index}
                      >
                        <FolderTree
                          showVersionAsText={showVersionAsText}
                          isAddingFromInsights={isAddingFromInsights}
                          isVersions={isVersions}
                          data={structureTree}
                          showCheckbox={false}
                          readOnly
                          initOpenStatus="closed"
                          indentPixels={30}
                          isHierarchy={showHierarchyControls}
                          isCaretLeft
                          noImports={!isDA}
                          hasTooltip={hasTooltip}
                          onCaretClick={
                            (type === 'page_versions'
                              ? onExpandPageVersions
                              : onExpandWidgets) as any
                          }
                          onNameClick={(e: any) => {
                            if (onNodeClick) {
                              onNodeClick(e, hierarchiesData[index]);
                              return;
                            }

                            if (
                              e.nodeData?.widgetConfig != null &&
                              e.nodeData?.versionId
                            ) {
                              goToVersion(e.nodeData?.versionId, 'widgets');
                            } else if (
                              e.nodeData?.pageConfig != null &&
                              e.nodeData?.versionId
                            ) {
                              const parentId =
                                hierarchiesData[index].structure.versionId;

                              const hierarchyPath = `${parentId}/${e.nodeData?.versionId}`;

                              goToVersion(
                                e.nodeData?.versionId,
                                'pages',
                                hierarchyPath,
                              );
                            } else if (
                              e.nodeData?.structureConfig != null &&
                              e.nodeData?.versionId
                            ) {
                              goToVersion(
                                isDA
                                  ? `${EDIT_PATH}/${e.nodeData?.versionId}`
                                  : e?.nodeData?.id,
                                'solutions',
                              );
                            }
                          }}
                        />
                      </StructureWrapper>
                    ))}
                  </TooltipPosition.Provider>
                </div>
              ) : (
                <div style={{ display: 'block', padding: '8px 10px' }}>
                  <b>No structures</b>
                </div>
              )}
            </div>
          )}
        </InfiniteScroll>
      </div>
    </ResizeSensor2>
  );
};

export default Hierarchy;
