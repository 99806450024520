import React, { FC } from 'react';
import { ReactComponent as InfoPointGray } from '../../assets/icons/info-point-gray.svg';
import { WidgetStand } from '../../gql/widget/types';

interface Props {
  stand?: WidgetStand;
}

const GraphStand: FC<Props> = ({ stand }) => {
  const isQuery = stand?.query && stand?.dateColumn;

  if (!stand?.source && !isQuery) {
    return null;
  }

  return (
    <div
      style={{
        marginTop: '26px',
        color: '#cacbcb',
        display: 'flex',
        alignItems: 'flex-start',
        flexWrap: 'wrap',
      }}
    >
      {stand?.source && (
        <div className="u-position-relative u-margin-right-5">
          {`Quelle: ${stand.source}`}
          <div
            className="u-position-absolute"
            style={{ top: 0, left: '-23px' }}
          >
            <InfoPointGray />
          </div>
        </div>
      )}
      {isQuery && (
        <div className="u-position-relative">
          {`Stand: ${stand.dateColumn}`}
          {!stand.source && (
            <div
              className="u-position-absolute"
              style={{ top: 0, left: '-23px' }}
            >
              <InfoPointGray />
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default GraphStand;
