import { useQuery } from '@apollo/client';
import { Monaco } from '@monaco-editor/react';
import { editor as Editor, MarkerSeverity } from 'monaco-editor';
import React, { FC, useCallback, useEffect, useMemo } from 'react';
import { META_LABEL } from '../../../components/EditorPanels/MetaInfo';
import { useAthenaQuery } from '../../../gql/dataSource/hooks';
import { VERSION_TYPE } from '../../../gql/dataSource/types';
import {
  GET_ALL_PAGES_PAGE_FILTERS_IDS,
  GetAllPagesPageFiltersIdsData,
  GetAllPagesPageFiltersIdsVars,
} from '../../../gql/page/queries';
import {
  GET_ALL_QUERIES,
  GET_ALL_QUERY_NON_LIVE_UNIQUE_IDS,
  GetAllQueriesData,
  GetAllQueriesVars,
  GetAllQueryNonLiveUniqueIdsData,
  GetAllQueryNonLiveUniqueIdsVars,
} from '../../../gql/query/queries';
import {
  AGGREGATION_FUNCTION,
  CONFIG_ELEMENT_VALUES_TYPE,
  DATA_HIGHLIGHT_SOURCE,
  DOMAIN_TYPE,
  FILTER_TYPES,
  LEGEND_PLACEMENT,
  MAP_BG_TYPE,
  SCALE_LOGIC,
  SORT_ORDER,
  WIDGET_TYPE,
} from '../../../gql/widget/types';
import { POLYTEIA_COLORS } from '../../../utils/colors';
import { useCreateQueryFromEditor } from '../../../utils/editor';
import { useStandaloneEditor } from '../../../utils/hooks/editor';
import { useValidationEnum } from '../../../utils/hooks/useValidationEnum';
import { PIVOT_FUNCTION } from '../../../utils/pivot';
import { useIdsField } from '../../Sites/SiteEdit/SiteSchema';

const colorsEnum = POLYTEIA_COLORS.map(({ color }) => color);
const colorsDescriptionEnum = POLYTEIA_COLORS.map(({ name }) => name);

const anyColorField = {
  anyOf: [
    {
      type: 'string',
      enum: colorsEnum,
      enumDescriptions: colorsDescriptionEnum,
    },
    {
      type: 'string',
    },
  ],
};

const domainPoint = {
  anyOf: [
    {
      type: 'string',
      enum: ['$now', '*'],
      enumDescriptions: ['This year', 'start/end'],
    },
    {
      type: 'string',
    },
  ],
};

export const TYPES_WITHOUT_HOVERS = [
  WIDGET_TYPE.TABLE,
  WIDGET_TYPE.SIMPLE_STAT,
];
export const TYPES_WITHOUT_WIDGET_FILTERS = [WIDGET_TYPE.SIMPLE_STAT];

export const PIVOT_WIDGET_TYPES = [
  WIDGET_TYPE.BAR_CHART,
  WIDGET_TYPE.GROUPED_COLUMN_CHART,
  WIDGET_TYPE.LINE_CHART,
  WIDGET_TYPE.STACKED_COLUMN_CHART,
  WIDGET_TYPE.TABLE,
];

export const CHART_WIDGET_TYPES = [
  WIDGET_TYPE.BAR_CHART,
  WIDGET_TYPE.GROUPED_COLUMN_CHART,
  WIDGET_TYPE.LINE_CHART,
  WIDGET_TYPE.STACKED_COLUMN_CHART,
];

export const valuesTypeEnum = Object.values(CONFIG_ELEMENT_VALUES_TYPE);
export const pivotFunctionEnum = Object.values(PIVOT_FUNCTION);
export const aggregationFunctionEnum = Object.values(AGGREGATION_FUNCTION);
export const domainTypeEnum = Object.values(DOMAIN_TYPE);
export const sortOrderEnum = Object.values(SORT_ORDER);

const staticWidgetFiltersSchema = {
  id: {
    type: 'string',
    minLength: 1,
  },
  title: {
    type: 'string',
  },
  type: {
    type: 'string',
    enum: Object.values(FILTER_TYPES),
  },
  info: {
    type: 'string',
  },
  valuesType: {
    type: 'string',
    enum: valuesTypeEnum,
  },
  column: {
    type: 'string',
    required: true,
    minLength: 1,
  },
  labels: {
    type: 'string',
  },
  injectionColumn: {
    type: 'string',
  },
  defaultValueQueryId: {
    type: 'string',
  },
  defaultValueColumn: {
    type: 'string',
  },
  totalAlgo: {
    type: 'string',
    enum: ['label', 'sum', 'none'],
  },
  totalLabel: {
    type: 'string',
  },
  showTotal: {
    type: 'boolean',
  },
  queryId: {
    type: 'string',
  },
  defaultSelectedFilter: {
    type: 'string',
  },
  tiltLabels: {
    type: 'boolean',
  },
  dependencies: {
    type: 'object',
    properties: {
      widget: {
        type: 'array',
        items: {
          type: 'object',
          properties: {
            id: {
              type: 'string',
            },
            column: {
              type: 'string',
            },
          },
        },
      },
      page: {
        type: 'array',
        items: {
          type: 'object',
          properties: {
            id: {
              type: 'string',
            },
            column: {
              type: 'string',
            },
          },
        },
      },
    },
  },
};

const TOP_LEVEL_QUERY = 'TOP_LEVEL_QUERY';
// const WIDGET_FILTER_QUERY = 'WIDGET_FILTER_QUERY';

const useFiltersSchema = (anyColumnField: any, queryIdsField: any) =>
  useMemo(() => {
    // return cols?.length
    //   ? cols.map((columns) => {
    //       const columnField = {
    //         type: 'string',
    //         ...(columns !== ASTERIX && columns.length ? { enum: columns } : {}),
    //       };
    //
    //       return {
    //         type: 'object',
    //         properties: {
    //           ...staticWidgetFiltersSchema,
    //           column: columnField,
    //           labels: columnField,
    //           injectionColumn: anyColumnField,
    //         },
    //       };
    //     })
    //   : {
    //       type: 'object',
    //       properties: {
    //         ...staticWidgetFiltersSchema,
    //         injectionColumn: anyColumnField,
    //       },
    //     };

    return {
      type: 'object',
      required: ['queryId'],
      properties: {
        ...staticWidgetFiltersSchema,
        queryId: queryIdsField,
        defaultValueQueryId: queryIdsField,
        injectionColumn: anyColumnField,
      },
    };
  }, [anyColumnField, queryIdsField]);

type Props = {
  setSchema: (schema: Record<string, any>) => void;
  // widgetFilterQueries: string[];
  // scenarioQueries: string[];
  editor: Editor.IStandaloneDiffEditor | Editor.IStandaloneCodeEditor | null;
  monaco: Monaco | null;
  type?: WIDGET_TYPE;
  editorFields: Record<string, any>;
  editorValue: string;
  setEditorValue: React.Dispatch<React.SetStateAction<string>>;
};

const WidgetSchema: FC<Props> = ({
  setSchema,
  editorFields,
  // widgetFilterQueries,
  // scenarioQueries,
  editor,
  monaco,
  type,
  editorValue,
  setEditorValue,
}) => {
  const { query, text, variables } = editorFields;

  const { data: pagesData } = useQuery<
    GetAllPagesPageFiltersIdsData,
    GetAllPagesPageFiltersIdsVars
  >(GET_ALL_PAGES_PAGE_FILTERS_IDS, {
    fetchPolicy: 'network-only',
  });

  const { data: queriesData, refetch: refetchQueries } = useAthenaQuery<
    GetAllQueriesData,
    GetAllQueriesVars
  >(GET_ALL_QUERIES, {
    variables: { versionType: VERSION_TYPE.LIVE },
    fetchPolicy: 'network-only',
  });

  const { data: queriesNonLiveData, refetch: refetchNonLiveQueries } =
    useAthenaQuery<
      GetAllQueryNonLiveUniqueIdsData,
      GetAllQueryNonLiveUniqueIdsVars
    >(GET_ALL_QUERY_NON_LIVE_UNIQUE_IDS, {
      fetchPolicy: 'network-only',
    });

  const rawQueryIdsField = useIdsField(
    queriesData?.allQueriesByType || [],
    'queryConfig',
    'queries',
    true,
  );

  const queryIdsField = useCreateQueryFromEditor(
    rawQueryIdsField,
    queriesNonLiveData?.allQueryNonLiveUniqueIDs || [],
    editorValue,
    setEditorValue,
    refetchQueries,
    refetchNonLiveQueries,
  );

  const pages = useValidationEnum(pagesData);

  const pageFiltersIdField = useMemo(() => {
    const descriptions: string[] = [];

    const ids = pages
      .filter(
        ({ pageConfig }) =>
          pageConfig?.pageFilters || pageConfig?.filterSections,
      )
      .flatMap(({ pageConfig, ...meta }) =>
        [
          ...(pageConfig?.pageFilters?.filter(({ id }) => id) || []),
          ...(pageConfig?.filterSections
            ?.flatMap((section) => section?.filters)
            ?.filter((filter) => filter?.id) || []),
        ].map((filter) => {
          const pageName = pageConfig?.name
            ? `Page name: ${pageConfig.name}\n`
            : '';

          descriptions.push(
            `${pageName}${Object.entries(meta).reduce(
              (acc, [key, val]) =>
                acc +
                (val && META_LABEL[key] ? `${META_LABEL[key]}: ${val}\n` : ''),
              '',
            )}`,
          );
          return filter?.id;
        }),
      );

    return {
      type: 'string',
      enum: ids,
      enumDescriptions: descriptions,
    };
  }, [pages]);

  // const columns = useMemo(() => getColumnNamesFromSqlQuery(query), [query]);

  // const isSqlValid = useMemo(
  //   () => columns === ASTERIX || !!columns.length || !query,
  //   [columns, query],
  // );

  const isSqlValid = query
    ? /WHERE(\\r)?(\\n)?\s+1\s?=\s?1/i.test(query)
    : true;

  // if not textwidget => true
  // if textwidget with no variables in text => true
  // if textwidget with any of variable not found in variables => false
  const invalidTextVariables =
    type === WIDGET_TYPE.TEXT &&
    text
      ?.match(/{@[_a-zA-Z]+}/gm)
      // convert {@var} to var
      ?.map((variableName: string) => variableName.replace(/[@{}]/g, ''))
      ?.find((variableName: string) => {
        return !variables?.some(
          (variable: { name: string }) => variable.name === variableName,
        );
      });

  const columnField = useMemo(
    () => ({
      type: 'string',
      required: true,
      minLength: 1,
      // ...(columns !== ASTERIX && columns.length ? { enum: columns } : {}),
    }),
    [],
  );

  // const widgetFiltersColumns = useMemo(
  //   () => widgetFilterQueries.map((q) => getColumnNamesFromSqlQuery(q)),
  //   [widgetFilterQueries],
  // );
  //
  // const scenariosColumns = useMemo(
  //   () => scenarioQueries.map((q) => getColumnNamesFromSqlQuery(q)),
  //   [scenarioQueries],
  // );

  const anyColumnField = useMemo(
    () => ({
      anyOf: [
        // {
        //   type: 'string',
        //   ...(columns !== ASTERIX && columns.length ? { enum: columns } : {}),
        // },
        {
          type: 'string',
          required: true,
          minLength: 1,
        },
      ],
    }),
    [],
  );

  const widgetFilterItemsSchema = useFiltersSchema(
    // widgetFiltersColumns,
    anyColumnField,
    queryIdsField,
  );

  const scenarioItemsSchema = useFiltersSchema(
    // scenariosColumns,
    anyColumnField,
    queryIdsField,
  );

  const standaloneEditor = useStandaloneEditor(editor);
  const model = useMemo(() => standaloneEditor?.getModel(), [standaloneEditor]);

  const validateText = useCallback(() => {
    if (!model || !monaco?.editor) {
      return;
    }

    if (!invalidTextVariables) {
      monaco.editor.setModelMarkers(model, TOP_LEVEL_QUERY, []);

      return;
    }

    const match = model.findNextMatch(
      `{@${invalidTextVariables}}`,
      { column: 1, lineNumber: 1 },
      false,
      true,
      '',
      false,
    );

    if (!match) {
      return;
    }
  }, [invalidTextVariables, model, monaco?.editor]);

  const validateSql = useCallback(() => {
    if (!model || !monaco?.editor) {
      return;
    }

    if (isSqlValid) {
      monaco.editor.setModelMarkers(model, TOP_LEVEL_QUERY, []);

      return;
    }

    const match = model.findNextMatch(
      'query',
      { column: 1, lineNumber: 1 },
      false,
      true,
      '"',
      false,
    );

    if (!match) {
      return;
    }

    const {
      range: { startLineNumber, endLineNumber, startColumn, endColumn },
    } = match;

    monaco.editor.setModelMarkers(model, TOP_LEVEL_QUERY, [
      {
        startLineNumber,
        endLineNumber,
        startColumn,
        endColumn,
        message: 'Please insert a WHERE 1 = 1 statement',
        severity: MarkerSeverity.Error,
      },
    ]);
  }, [isSqlValid, model, monaco?.editor]);

  // const validateWidgetsFiltersSql = useCallback(() => {
  //   if (!model || !monaco?.editor) {
  //     return;
  //   }
  //
  //   const matches = model.isDisposed()
  //     ? []
  //     : model.findMatches('query', true, false, true, '"', false).slice(1);
  //
  //   if (!matches.length) {
  //     monaco.editor.setModelMarkers(model, WIDGET_FILTER_QUERY, []);
  //
  //     return;
  //   }
  //
  //   let markers: Editor.IMarkerData[] = [];
  //
  //   widgetFiltersColumns.forEach((columns, ind) => {
  //     if (widgetFilterQueries[ind] === undefined) {
  //       return;
  //     }
  //
  //     const match = matches.shift();
  //
  //     if (!match) {
  //       return;
  //     }
  //
  //     if (columns === ASTERIX || !!columns.length) {
  //       return;
  //     }
  //
  //     const {
  //       range: { startLineNumber, endLineNumber, startColumn, endColumn },
  //     } = match;
  //
  //     markers.push({
  //       startLineNumber,
  //       endLineNumber,
  //       startColumn,
  //       endColumn,
  //       message: 'The query is not a valid SQL statement',
  //       severity: MarkerSeverity.Error,
  //     });
  //   });
  //
  //   monaco.editor.setModelMarkers(model, WIDGET_FILTER_QUERY, markers);
  // }, [model, monaco?.editor, widgetFilterQueries, widgetFiltersColumns]);

  useEffect(() => {
    validateSql();
    validateText();
    // validateWidgetsFiltersSql();
  }, [validateSql, validateText]);

  const schema = useMemo(() => {
    return {
      type: 'object',
      required: type === WIDGET_TYPE.TEXT ? [] : ['queryId'],
      properties: {
        title: {
          type: 'string',
        },
        name: {
          type: 'string',
        },
        text: {
          type: 'string',
        },
        variables: {
          type: 'array',
          items: {
            additionalProperties: false,
            type: 'object',
            required: ['queryId'],
            properties: {
              type: {
                type: 'string',
                enum: ['text', 'number'],
                required: true,
              },
              name: { type: 'string', required: true, minLength: 1 },
              column: { type: 'string', required: true, minLength: 1 },
              queryId: queryIdsField,
            },
          },
        },
        type: {
          type: 'string',
          enum: Object.values(WIDGET_TYPE),
        },
        queryId: queryIdsField,
        stand: {
          type: 'object',
          required: ['queryId'],
          properties: {
            queryId: queryIdsField,
            dateColumn: {
              type: 'string',
            },
            source: {
              type: 'string',
            },
          },
        },
        showDots: {
          type: 'string',
          default: 'auto',
          enum: ['auto', 'always', 'never'],
        },
        zeroBased: {
          type: 'boolean',
          default: false,
        },
        info: {
          type: 'string',
        },
        legendPlacement: {
          type: 'string',
          enum: Object.values(LEGEND_PLACEMENT),
        },
        scaleLogic: {
          type: 'string',
          enum: [SCALE_LOGIC.FROM_ZERO, SCALE_LOGIC.FROM_MIN],
        },
        negativeColor: anyColorField,
        positiveColor: anyColorField,
        neutralColor: anyColorField,
        background: {
          type: 'object',
          properties: {
            type: {
              type: 'string',
              enum: Object.values(MAP_BG_TYPE),
            },
          },
        },
        ...(!TYPES_WITHOUT_WIDGET_FILTERS.includes(type as WIDGET_TYPE)
          ? {
              widgetFilters: {
                type: 'array',
                items: widgetFilterItemsSchema,
              },
            }
          : {}),
        ...(!TYPES_WITHOUT_WIDGET_FILTERS.includes(type as WIDGET_TYPE)
          ? {
              filterSections: {
                type: 'array',
                items: {
                  type: 'object',
                  properties: {
                    title: {
                      type: 'string',
                    },
                    keepDefault: {
                      type: 'boolean',
                      required: true,
                    },
                    defaultLabel: {
                      type: 'string',
                    },
                    filteredLabel: {
                      type: 'string',
                    },
                    filters: {
                      type: 'array',
                      items: scenarioItemsSchema,
                    },
                  },
                },
              },
            }
          : {}),
        pageFilters: {
          type: 'array',
          items: {
            type: 'object',
            properties: {
              id: pageFiltersIdField,
              column: anyColumnField,
              type: {
                type: 'string',
                enum: Object.values(FILTER_TYPES),
              },
              valuesType: {
                type: 'string',
              },
            },
          },
        },
        hovers: {
          type: 'array',
          items: {
            type: 'object',
            properties: {
              type: {
                type: 'string',
                enum: valuesTypeEnum,
              },
              title: {
                type: 'string',
              },
              column: columnField,
              labels: columnField,
              refersTo: {
                type: 'string',
              },
            },
          },
        },
        data: {
          type:
            type === WIDGET_TYPE.SIMPLE_STAT /* || type === WIDGET_TYPE.TABLE */
              ? 'object'
              : 'array',
          properties: {
            id: {
              type: 'string',
            },
            title: {
              type: 'string',
            },
            numberColumn: {
              type: 'string',
            },
            numberType: {
              type: 'string',
              enum: valuesTypeEnum,
            },
            diffColumn: {
              type: 'string',
            },
            diffColorColumn: {
              type: 'string',
            },
            diffIndicatorColumn: {
              type: 'string',
            },
            info: {
              type: 'string',
            },
          },
          items: {
            type: 'object',
            properties: {
              id: {
                type: 'string',
              },
              title: {
                type: 'string',
              },
              showInHover: {
                type: 'boolean',
              },
              ...(type === WIDGET_TYPE.CHOROPLETH_MAP
                ? {}
                : {
                    numberType: {
                      type: 'string',
                      enum: valuesTypeEnum,
                    },
                  }),
              color: anyColorField,
              type: {
                type: 'string',
                enum: valuesTypeEnum,
              },
              column: columnField,
              labels: columnField,
              info: {
                type: 'string',
              },
              weight: {
                type: 'number',
              },
              align: {
                type: 'string',
              },
              borderRight: {
                type: 'boolean',
              },
              sortable: {
                type: 'boolean',
              },
              ...(type === WIDGET_TYPE.TABLE
                ? {
                    total: {
                      type: 'string',
                      default: 'none',
                      enum: ['sum', 'avg', 'none'],
                    },
                    totalLabel: {
                      type: 'string',
                    },
                  }
                : {}),
              header: {
                type: 'string',
              },
              colorColumn: columnField,
              infoColumn: columnField,
              additionalTextColumn: columnField,
              highlights: {
                type: 'array',
                items: {
                  type: 'object',
                  properties: {
                    start: domainPoint,
                    end: domainPoint,
                    type: {
                      type: 'string',
                      enum: ['dashed', 'dotted', 'translucent'],
                    },
                    legend: {
                      type: 'string',
                    },
                    source: {
                      type: 'string',
                      enum: Object.values(DATA_HIGHLIGHT_SOURCE),
                    },
                    column: {
                      type: 'string',
                    },
                    queryId: queryIdsField,
                  },
                },
              },
            },
          },
        },
        domains: {
          type: 'array',
          items: {
            type: 'object',
            properties: {
              title: {
                type: 'string',
              },
              type: {
                type: 'string',
                enum: ['xAxis', 'yAxis'],
              },
              column: columnField,
              tiltLabels: {
                type: 'boolean',
              },
              showValue: {
                type: 'boolean',
              },
              showAllLabels: {
                type: 'boolean',
              },
              order: {
                type: 'string',
                enum: sortOrderEnum,
              },
              maxValue: {
                type: 'number',
              },
              valuesType: {
                type: 'string',
                enum: valuesTypeEnum,
              },
              separators: {
                type: 'array',
                items: {
                  type: 'object',
                  properties: {
                    type: {
                      type: 'string',
                      enum: domainTypeEnum,
                    },
                    text: {
                      type: 'string',
                    },
                    position: {
                      type: 'string',
                    },
                    queryId: queryIdsField,
                  },
                },
              },
              highlights: {
                type: 'array',
                items: {
                  type: 'object',
                  properties: {
                    type: {
                      type: 'string',
                      enum: domainTypeEnum,
                    },
                    source: {
                      type: 'string',
                      enum: Object.values(DATA_HIGHLIGHT_SOURCE),
                    },
                    color: anyColorField,
                    start: {
                      type: 'string',
                    },
                    end: {
                      type: 'string',
                    },
                    queryId: queryIdsField,
                  },
                },
              },
            },
          },
        },
        pivot: {
          type: 'object',
          properties: {
            pivotColumn: {
              type: 'string',
            },
            dataColumn: {
              type: 'string',
            },
            type: {
              type: 'string',
              enum: valuesTypeEnum,
            },
            pivotFunction: {
              type: 'string',
              enum: pivotFunctionEnum,
            },
            info: {
              type: 'string',
            },
            orderBy: {
              type: 'string',
            },
            orderDirection: {
              type: 'string',
            },
            aggregationLabel: {
              type: 'string',
            },
            aggregationFunction: {
              type: 'string',
              enum: aggregationFunctionEnum,
            },
            colors: {
              type: 'array',
              items: {
                type: 'string',
              },
            },
            groupBy: {
              type: 'string',
            },
            total: {
              type: 'string',
              default: 'none',
              enum: ['sum', 'avg', 'none'],
            },
            totalLabel: {
              type: 'string',
            },
          },
        },
      },
    };
  }, [
    anyColumnField,
    columnField,
    pageFiltersIdField,
    queryIdsField,
    scenarioItemsSchema,
    type,
    widgetFilterItemsSchema,
  ]);

  useEffect(() => {
    if (pagesData && queriesData) {
      setSchema(schema);
    }
  }, [pagesData, queriesData, schema, setSchema]);

  return null;
};

export default WidgetSchema;
