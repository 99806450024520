import { Dispatch, ReactElement, SetStateAction } from 'react';
import { DataType } from '../../components/PivotChartPanel';
import { FilterSelection } from '../../components/SimpleFilter';
import { Setting } from '../../components/SimpleFilter/WidgetSettings';
import { BACKGROUND_COLOR_TAG } from '../../components/Widget/widgets/simple_table/@constants';
import { Metadata } from '../common/types';
import { Access, AccessUser } from '../types';

export enum MAP_BG_TYPE {
  OSM = 'openstreetmaps',
}

export enum CONFIG_ELEMENT_VALUES_TYPE {
  string = 'string',
  number = 'number',
  percent = 'percent',
  sqkm = 'sqkm',
  currencyEUR = 'currencyEUR',
  celsius = 'celsius',
  ugperm3 = 'ugperm3',
  date = 'date',
}

export enum AGGREGATION_FUNCTION {
  AVG = 'avg',
  SUM = 'sum',
}

export enum DATA_HIGHLIGHT_SOURCE {
  FIXED = 'fixed',
  AUTOMATIC = 'automatic',
}

export enum DOMAIN_TYPE {
  DYNAMIC = 'dynamic',
  STATIC = 'static',
}

export enum SORT_ORDER {
  ASC = 'asc',
  DESC = 'desc',
}

export type WidgetPivot = {
  pivotColumn?: string;
  dataColumn?: string;
  type?: string;
  pivotFunction?: string;
  info?: string;
  orderBy?: string;
  orderDirection?: string;
  aggregationFunction?: AGGREGATION_FUNCTION;
  aggregationLabel?: string;
  colors?: string[];
  groupBy?: string;
  total?: 'sum' | 'avg' | 'none';
  totalLabel?: string;
  totalValue?: number;
  filters?: WidgetPivotFilter[];
};

export type WidgetPivotFilter = {
  id: string;
  column: string;
  valuesType: DataType;
  title?: string;
  type: FILTER_TYPES;
  showTotal?: boolean;
  totalLabel?: string;
};

export type WidgetVariable = {
  name: string;
  type: string;
  column: string;
  query: string;
};

export type FilterSection = {
  filters: WidgetFilter[];
  keepDefault: boolean;
  title: string;
  defaultLabel: string;
  filteredLabel: string;
};

export type WidgetConfig = {
  type: WIDGET_TYPE;
  name: string;
  title: string;
  queryId?: string;
  info: string;
  zeroBased?: boolean;
  showDots?: 'auto' | 'always' | 'never';
  widgetFilters: WidgetFilter[];
  pageFilters: PageFilter[];
  hovers: ChartHover[];
  data: WidgetData[];
  domains?: Domain[];
  stand?: WidgetStand;
  variables?: WidgetVariable[];
  pivot?: WidgetPivot;
  filterSections?: FilterSection[];
};

export type WidgetPreviewConfig = {
  type: WIDGET_TYPE;
  name: string;
  title?: string;
  queryId: string;
  widgetFilters?: WidgetFilter[];
  pageFilters?: PageFilter[];
  info?: string;
  stand?: WidgetStand;
  pivot?: WidgetPivot;
  variables?: WidgetVariable[];
  query?: string;
  domains?: Domain[];
  zeroBased?: boolean;
};

export type DependantFilter = {
  id: string;
  column: string;
};

export type FilterDependencies = {
  page: DependantFilter[];
  widget: DependantFilter[];
};

export type WidgetFilter = {
  id: string;
  title: string;
  type: FILTER_TYPES;
  valuesType: CONFIG_ELEMENT_VALUES_TYPE;
  column: string;
  labels: string;
  totalAlgo: 'label' | 'sum' | 'none';
  totalLabel: string;
  showTotal: boolean;
  injectionColumn: string;
  queryId: string;
  query?: string;
  dependencies: FilterDependencies;
  defaultValueColumn: string;
  defaultValueQueryId: string;
  defaultSelectedFilter: string;
};

export type PageFilter = {
  id: string;
  column: string;
};

export type WidgetData = {
  id: string;
  title: string;
  type: CONFIG_ELEMENT_VALUES_TYPE;
  column: string;
  labels: string;
  info: string;
  showInHover: boolean;
  color: string;
};

export type ChartHover = {
  type: string;
  title: string;
  column: string;
  labels: string;
  refersTo: string;
};

export type BuildChartHover = {
  type: string;
  title: string;
  labels: string[];
  values: string[];
  refersTo: string;
};

export type Separator = {
  position: string;
  text: string;
  query?: string;
};

export enum HIGHLIGHT_TYPE {
  DASHED = 'dashed',
  DOTTED = 'dotted',
  TRANSLUCENT = 'translucent',
}

export enum DOMAIN_POINT {
  NOW = '$now',
  ASTERIX = '*',
}

export type Highlight = {
  color: string;
  start: string;
  end: string;
  type: HIGHLIGHT_TYPE;
  legend: string;
  source: 'fixed' | 'automatic';
  column?: string;
  automaticHighlightsMap?: boolean[];
  isScenario?: boolean;
};

export type Domain = {
  type: DOMAIN_TYPE;
  title: string;
  column: string;
  tiltLabels: boolean;
  showValue: boolean;
  showAllLabels: boolean;
  separators: Separator[];
  highlights: Highlight[];
  order: SORT_ORDER;
  valuesType: CONFIG_ELEMENT_VALUES_TYPE;
  maxValue?: number;
};

export type WidgetStand = {
  query?: string;
  dateColumn?: string;
  source?: string;
};

export type WidgetAccess = Access & { users: AccessUser[] };

export type Widget = Metadata & {
  widgetConfig: WidgetConfig;
  access: WidgetAccess;
  structureId: string;
};

export enum FILTER_TYPES {
  FREETEXT = 'freetext',
  DROPDOWN = 'dropdown',
  SEARCHABLE_DROPDOWN = 'searchable_dropdown',
  SLIDER = 'slider',
  FREETEXT_VECTOR = 'freetext_vector',
  MULTISELECT_SEARCHABLE = 'multiselect_searchable',
  MULTISELECT_DROPDOWN = 'multiselect_dropdown',
}

export const isMultipleFilterType = (type?: FILTER_TYPES) =>
  type &&
  [
    FILTER_TYPES.MULTISELECT_DROPDOWN,
    FILTER_TYPES.MULTISELECT_SEARCHABLE,
  ].includes(type);

export type AppliedWidgetFilter = {
  filterId: string;
  value?: any;
  multiValue?: string[];
  title?: string;
  type?: FILTER_TYPES;
};

export type BuildWidgetInput = {
  structureId: string;
  widgetId: string;
  appliedFilters?: AppliedWidgetFilter[];
  organizationId?: string;
  dataSourceId?: string;
};

export type DefaultAppliedFilter = {
  filterId: string;
  value?: string;
  multiValue?: string[];
  title?: string;
  type?: FILTER_TYPES;
};

export type DefaultWidgetFilter = {
  widgetId: string;
  filters?: DefaultAppliedFilter[];
};

export type ChartDomainConfig = Omit<Domain, 'column'> & {
  values: string[];
};

export type ChartDataConfig = ChartDomainConfig & {
  id: string;
  labels: string;
  info: string;
  type: CONFIG_ELEMENT_VALUES_TYPE;
  color?: string;
};

export type BuildChartFields = {
  domains: ChartDomainConfig[];
  data: ChartDataConfig[];
  hover: BuildChartHover[];
};

export type LineChartConfig = Omit<WidgetConfig, 'domains' | 'data'> &
  BuildChartFields;

export enum WIDGET_TYPE {
  LINE_CHART = 'line_chart',
  PIE_CHART = 'pie_chart',
  CHOROPLETH_MAP = 'choropleth_map',
  STACKED_COLUMN_CHART = 'stacked_column_chart',
  GROUPED_COLUMN_CHART = 'grouped_column_chart',
  TABLE = 'table',
  BAR_CHART = 'bar_chart',
  SIMPLE_STAT = 'simple_stat',
  TEXT = 'text',
}

export interface SelectedWidgetParams {
  id: string;
  value: string | string[];
  type: FILTER_TYPES;
  title: string;
}

export type OnFilterChange = (params: SelectedWidgetParams[]) => void;

export enum SCALE_LOGIC {
  FROM_MIN = 'fromMin',
  FROM_ZERO = 'fromZero',
}

export enum DOMAIN_TYPE {
  X_AXIS = 'xAxis',
  Y_AXIS = 'yAxis',
}

export enum COLUMN_TYPE {
  DIMENSION = 'DIMENSION',
  MEASURE = 'MEASURE',
}

export type QueryCol = {
  title: string;
  name: string;
  description?: string;
  type?: COLUMN_TYPE;
  valuesType?: CONFIG_ELEMENT_VALUES_TYPE;
};

export type InsightRawTableData = {
  header: QueryCol[];
  content: string[][];
  total: number;
};

export type TextWidgetConfig = {
  name: string;
  text: string;
  title: string;
  type: 'text';
  versionId?: string;
};

export type BuiltWidgetFilter = {
  id: string;
  title: string;
  type: FILTER_TYPES;
  info: string;
  valuesType: CONFIG_ELEMENT_VALUES_TYPE;
  column: string;
  totalAlgo: 'sum' | 'average';
  totalLabel: string;
  showTotal: boolean;
  dependencies: string[];
  values: string[];
  labels: string[];
  defaultSelectedFilter?: string;
  tiltLabels?: boolean;
};

export enum LEGEND_PLACEMENT {
  LEFT = 'left',
  RIGHT = 'right',
}

export type WidgetCommonProps = {
  afterTitle?: ReactElement;
  onFilterChange?: OnFilterChange;
  filtersVal?: FilterSelection[];
  settings?: Setting[];
  settingsVal?: Record<string, any>;
  setSettingsVal?: Dispatch<SetStateAction<Record<string, any>>>;
  noScenariosData?: ChartDataConfig[];
  isEditing?: boolean;
  isCreatedByCustomer?: boolean;
  creatorFullName?: string;
  createdAt?: string;
  editProps?: { id: string; isPreview: boolean };
  handleDelete?: () => void;
  handleReload?: () => void;
};

export type TableSpanningHeader = {
  colspan: number;
  borderRight: boolean;
  title: string;
};

export type TableHeader = {
  id: string;
  align: string;
  weight: number;
  borderRight: boolean;
  info: string;
  title: string;
  sortable: boolean;
};

export type BuiltTableData = {
  id: string;
  type: CONFIG_ELEMENT_VALUES_TYPE;
  additionalTextColumn: string;
  color: keyof typeof BACKGROUND_COLOR_TAG;
  info: string;
  additionalText: string;
  value: string;
  totalValue?: number;
  total?: 'sum' | 'avg' | 'none';
  totalLabel?: string;
};

export type TableConfig = Omit<WidgetConfig, 'domains' | 'data'> & {
  spanningHeader: TableSpanningHeader[];
  header: TableHeader[];
  data: BuiltTableData[][];
  sortable?: boolean;
};

export type Hover = {
  title: string;
  type: CONFIG_ELEMENT_VALUES_TYPE;
  values: string[];
  labels: string[];
  refersTo: string;
};

export type BuiltWidgetData = Omit<ChartDataConfig, 'labels'> & {
  id: string;
  info: string;
  type: CONFIG_ELEMENT_VALUES_TYPE;
  labels: string[];
  values: string[];
  title: string;
  color?: string;
  highlights?: Highlight[];
};

export type BuiltWidgetConfig = {
  type: WIDGET_TYPE;
  name: string;
  title: string;
  query: string;
  zeroBased: boolean;
  showDots: string;
  info: string;
  widgetFilters: BuiltWidgetFilter[];
  pageFilters: PageFilter[];
  hovers?: Hover[];
  domains: ChartDomainConfig[];
  data: BuiltWidgetData[];
  stand: WidgetStand;
  pivot?: WidgetPivot;
  filterSections?: FilterSection[];
};

export type Values = (number | undefined | string)[];

export interface DataSeries {
  id: string;
  title: string;
  info?: string;
  values: Values;
  color?: string;
  highlights?: Highlight[];
  type: CONFIG_ELEMENT_VALUES_TYPE;
}

export interface NewDataSeries {
  id: string;
  title: string;
  values: Values;
  color?: string;
  type: CONFIG_ELEMENT_VALUES_TYPE;
}

export type Properties = {
  value: string;
  label: string;
};

export type Geometry = {
  type: 'Polygon';
  coordinates: number[][][];
};
export type MultiGeometry = {
  type: 'MultiPolygon';
  coordinates: number[][][][];
};

export type Polygon = {
  type: string;
  properties: Properties;
  geometry: Geometry;
};

export type MultiPolygon = {
  type: string;
  properties: Properties;
  geometry: MultiGeometry;
};

export type Shape = {
  polygon: Polygon;
  multiPolygon: MultiPolygon;
};

export type Coordinates = {
  x: string;
  y: string;
};

declare enum MapChartBackgroundType {
  openstreetmaps = 'openstreetmaps',
}

declare type MapChartBackground = {
  type: MapChartBackgroundType;
};

export type MapChartConfig = Omit<BuiltWidgetConfig, 'data'> & {
  data: Array<BuiltWidgetData & { shapes: Shape[] }>;
  center: Coordinates;
  zoom: string;
  scaleLogic: SCALE_LOGIC;
  negativeColor: string;
  positiveColor: string;
  neutralColor: string;
  background?: MapChartBackground;
};

export type PieChartData = {
  title: string;
  type: CONFIG_ELEMENT_VALUES_TYPE;
  values: string[];
  labels: string;
  showInHover: boolean;
};

export type PieChartConfig = Omit<BuiltWidgetConfig, 'data'> & {
  legendPlacement: LEGEND_PLACEMENT;
  data: PieChartData[];
};

export enum DIFF_COLOR {
  blue = 'blue',
  green = 'green',
  red = 'red',
}

export enum DIFF_INDICATOR {
  UP = 'up',
  DOWN = 'down',
}

export type SimpleStatData = {
  id: string;
  title?: string;
  numberType?: CONFIG_ELEMENT_VALUES_TYPE;
  number: string;
  diff?: string;
  diffColor?: DIFF_COLOR;
  diffIndicator?: DIFF_INDICATOR;
  info?: string;
};

export type SimpleStatChartConfig = {
  data: SimpleStatData[];
} & BuiltWidgetConfig;

export type TextWidgetData = {
  type: string;
  name: string;
  text: string;
  title: string;
};
